import React, {useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import {IconTrash} from "@tabler/icons-react";

interface Props {
  setSignature: (content: string) => void;
}

const SignaturePad = ({setSignature}: Props) => {
  const sigRef = useRef();
  const handleSignatureEnd = () => {
    // @ts-ignore
    setSignature(sigRef.current.toDataURL());
  }
  const clearSignature = () => {
    // @ts-ignore
    sigRef.current.clear();
    setSignature(null);
  }
  
  return <div style={{border: '1px solid black', position: 'relative', width: 320, height: '450px'}}>
    <SignatureCanvas
      penColor="black"
      canvasProps={{width: '320px', height: '450px'}}
      ref={sigRef}
      onEnd={handleSignatureEnd}
    />
    <IconTrash size={24} onClick={clearSignature}
               style={{cursor: 'pointer', position: 'absolute', right: 10, bottom: 10}}/>
  </div>
};

export default SignaturePad;