import * as React from "react";
import FullWidthContainer from "../components/FullWidthContainer";
import { DataTable } from "../components/table/DataTable";
import { useSopralluoghi } from "../hooks/inspections/useSopralluoghi";
import { Box, Button, Title } from "@mantine/core";
import { useLocation } from "wouter";

const TITLE_HEIGHT = 42;

const Inspections = () => {
  const {
    sopralluoghi,
    pages,
    currentPage,
    tableFilters,
    setTableFilters,
    setCurrentPage,
    setFilter,
    setOrderColumn,
    orderColumnRef,
    orderDescending,
  } = useSopralluoghi();

  const [, setLocation] = useLocation();

  return (
    <FullWidthContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: TITLE_HEIGHT,
        }}
        mb={8}
      >
        <Title order={1}>Sopralluoghi</Title>
        <Button onClick={() => setLocation(`/sopralluoghi/create`)}>
          Crea
        </Button>
      </Box>
      <Box sx={{ height: `calc(100% - ${TITLE_HEIGHT}px)` }}>
        <DataTable
          tableFilters={tableFilters}
          setTableFilters={setTableFilters}
          onSearch={(searchValue) => setFilter(searchValue)}
          onSort={(columnRef) => {
            setOrderColumn(columnRef);
          }}
          sortingColumn={orderColumnRef}
          sortingDesc={orderDescending}
          header={[
            {
              accessor: "id",
              visible: false,
            },
            {
              accessor: "number",
              visible: true,
              label: "Numero",
            },
            {
              accessor: "customerErpCode",
              visible: true,
              label: "Cod. Arca",
            },
            {
              accessor: "customerDescription",
              visible: true,
              label: "Cod. Arca",
            },
            {
              accessor: "infestazioneInAtto",
              visible: true,
              label: "Infest. in Atto",
            },
            {
              accessor: "dataSopralluogo",
              visible: true,
              label: "Data sopralluogo",
            },
            {
              accessor: "evolutoInProposta",
              visible: true,
              label: "Evoluto in proposta",
            },
          ]}
          data={sopralluoghi ?? []}
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onClick={(id: string) => setLocation(`/sopralluoghi/${id}`)}
        />
      </Box>
    </FullWidthContainer>
  );
};

export default Inspections;
