import {
  Anchor,
  Box,
  Breadcrumbs,
  Col,
  Divider,
  Grid,
  Space,
  TextInput,
  Title,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React from "react";
import FullWidthContainer from "../components/FullWidthContainer";
import { useRoute } from "wouter";
import { useGetPropostaTecnicaQuery } from "../redux/apis/proposteTecnicheApi";
import InspectionMovement, {
} from "./Inspections/InspectionMovement";

function ProposteTecnicheDetail() {
  // @ts-ignore
  const [, { id }] = useRoute("/proposte/:id");
  const { data } = useGetPropostaTecnicaQuery(id);

  const breadcrumbsItems = [
    { title: "Home", href: "/" },
    { title: "Proposte", href: "/proposte" },
    { title: id, href: `/proposte/${id}` },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    data && (
      <FullWidthContainer
        style={{
          overflow: "hidden",
        }}
      >
        <div
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "100%",
            width: "100%",
            padding: "12px",
          }}
        >
          <Title mb={8}>Proposte Tecniche</Title>
          <Breadcrumbs>{breadcrumbsItems}</Breadcrumbs>
          <Space h="lg" />
          <Divider label={"Dati cliente"} />
          <Space h="lg" />
          <Grid>
            <Col span={2}>
              <TextInput
                label="Cod. Arca"
                value={data.relatedCustomer.erpCode}
                disabled
              />
            </Col>
            <Col span={6}>
              <TextInput
                label="Cliente"
                value={data.relatedCustomer.description}
                disabled
              />
            </Col>
            <Col span={4} />
            <Col span={4}>
              <TextInput
                label="Codice Fiscale"
                value={data.relatedCustomer.cf}
                disabled
              />
            </Col>
            <Col span={4}>
              <TextInput
                label="P.Iva"
                value={data.relatedCustomer.vat}
                disabled
              />
            </Col>
            <Col span={4} />
            <Col span={4}>
              <TextInput
                label="Città"
                value={data.relatedCustomer.city}
                disabled
              />
            </Col>
            <Col span={4}>
              <TextInput
                label="Provincia"
                value={data.relatedCustomer.province}
                disabled
              />
            </Col>
            <Col span={4}>
              <TextInput
                label="Indirizzo"
                value={data.relatedCustomer.address}
                disabled
              />
            </Col>
            <Col span={4}>
              <TextInput
                label="Zona"
                value={data.relatedCustomer.zone}
                disabled
              />
            </Col>
            <Col span={4}>
              <TextInput
                label="Cod. Postale"
                value={data.relatedCustomer.zipCode}
                disabled
              />
            </Col>
            <Col span={12}>
              <TextInput
                label="Note"
                value={data.relatedCustomer.note}
                disabled
              />
            </Col>
          </Grid>

          <Space h="lg" />
          <Divider label={"Dettaglio proposta"} />
          <Grid>
            <Col span={1}>
              <TextInput label="Numero" value={data.number} disabled />
            </Col>
            <Col span={2}>
              <DatePicker
                value={data.creationDate}
                label="Data creazione"
                renderDay={(date) => <Box>{date.getDate()}</Box>}
                disabled
              />
            </Col>
            <Col span={3}>
              <TextInput label="Tipologia" value={data.type} disabled />
            </Col>
            <Col span={2}>
              <TextInput
                label="Condizioni Pagamento"
                value={data.condizioniPagamento}
                disabled
              />
            </Col>
            <Col span={2}>
              <TextInput
                label="Condizioni Fatturazione"
                value={data.condizioniFatturazioni}
                disabled
              />
            </Col>
          </Grid>
          <Space h="lg" />
          <Divider label={"Movimenti"} />
          <Space h="lg" />
          <Grid>
            {data.movimenti.map((mov) => {
              return (
                <Grid.Col xs={3} key={mov.id}>
                  <InspectionMovement
                    infestante={mov.infestante}
                    costoServizio={mov.costoServizio}
                    schedule={mov.schedule}
                    tipologiaServizio={mov.tipologiaServizio}
                    attrezzatura={mov.attrezzatura}
                    raccomandazione={mov.raccomandazione}
                    specificheTecniche={mov.specificheTecniche}
                    onDelete={() => alert("ciao")}
                  />
                </Grid.Col>
              );
            })}
          </Grid>
        </div>
      </FullWidthContainer>
    )
  );
}

export default ProposteTecnicheDetail;
