import {Badge, Button, Card, Group, Text} from "@mantine/core"
import React from "react"
import {IconBarcode, IconBuildingWarehouse, IconCalendarTime} from "@tabler/icons-react";

interface Props {
  productName: string,
  barcode: string,
  quantity: number,
  shelf: string,
  lastUpdate: string,
  capacityThreshold: number,
  onClick: (id: string) => void
}


export const InventoryItemCardMobile: React.FC<Props> = ({
                                                           productName,
                                                           barcode,
                                                           quantity,
                                                           shelf,
                                                           lastUpdate,
                                                           onClick,
                                                           capacityThreshold
                                                         }: Props) => {
  
  return <Card shadow="md" radius='md' withBorder sx={{margin: '20px'}}>
    <Group position="apart" mt="md" mb="xs">
      <Text weight={700} size={18} w={"50%"}>{productName}</Text>
      <Badge color={quantity > capacityThreshold ? "green" : "red"} variant="light" w={"40%"}>
        Qnt: {quantity}/{capacityThreshold ?? "0"}
      </Badge>
    </Group>
    <hr/>
    <Text color="dimmed">
      <div style={{display: "flex"}}><IconBarcode style={{marginRight: 16}}/> {barcode}</div>
      <div style={{display: "flex"}}><IconBuildingWarehouse style={{marginRight: 16}}/> {shelf}</div>
      <div style={{display: "flex"}}><IconCalendarTime style={{marginRight: 16}}/> {lastUpdate}</div>
    </Text>
    
    <Button
      color='blue'
      variant={"light"}
      fullWidth sx={{marginTop: '20px'}}
      radius='md'
      onClick={() => onClick(barcode)}
    >Visualizza</Button>
  </Card>
}