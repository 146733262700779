import {useDebouncedValue} from "@mantine/hooks";
import {TableFilter} from "../components/table/DataTable";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {
  DEFAULT_PAGE_LIMIT,
  setCurrentPage,
  setFilter,
  setOrderColumnRef,
  setOrderDescending,
  setTableFilters,
} from "../redux/slices/inventorySlice";
import capitalize from "lodash-es/capitalize";
import {useGetInventoryItemsQuery} from "../redux/apis/inventoryApi";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const useInventory = (isLoadMore: boolean) => {
  const dispatch = useAppDispatch();
  const {
    pages,
    currentPage,
    filter,
    tableFilters,
    orderDescending,
    orderColumnRef,
  } = useAppSelector((s) => s.inventory);
  
  const [debouncedFilter] = useDebouncedValue(filter, 200);
  const {data} = useGetInventoryItemsQuery({
    search: debouncedFilter,
    orderBy: capitalizeFirstLetter(orderColumnRef),
    descending: orderDescending,
    limit: isLoadMore ? currentPage * DEFAULT_PAGE_LIMIT : DEFAULT_PAGE_LIMIT,
    skip: isLoadMore ? 0 : (currentPage - 1) * DEFAULT_PAGE_LIMIT,
    columnsFilter: tableFilters.map((x) => capitalize(x.columnRef)),
    columnsFiltersValues: tableFilters.map((x) => x.value),
  });
  
  const setOrderColumn = (orderColumn: string) => {
    const alreadySelected =
      orderColumn.toLowerCase() === orderColumnRef.toLowerCase();
    
    dispatch(setOrderDescending(orderDescending !== alreadySelected));
    dispatch(setOrderColumnRef(orderColumn));
  };
  
  return {
    inventoryItems: data?.items?.map(i => ({
      ...i,
      id: i.barcode,
    })) || [],
    pages,
    currentPage,
    tableFilters,
    setTableFilters: (prev: TableFilter) => dispatch(setTableFilters(prev)),
    setCurrentPage: (v: number) => dispatch(setCurrentPage(v)),
    filter,
    setFilter: (v: string) => dispatch(setFilter(v)),
    setOrderColumn,
    orderColumnRef,
    orderDescending
  };
};
