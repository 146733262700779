import React from "react";
import {Box, Title} from "@mantine/core";
import {DataTable} from "../../components/table/DataTable";
import {useLocation} from "wouter";
import ResponsiveFullWidthContainer from "../../components/ResponsiveFullWidthContainer";
import useIsMobile from "../../hooks/useIsMobile";
import {useClocking} from "../../hooks/useClocking";
import {ClockingListItemCardMobile} from "./ClockingListItemCardMobile";

const TITLE_HEIGHT = 80;

export const ClockingList: React.FC = () => {
  const isMobile = useIsMobile();
  
  const {
    items,
    pages,
    currentPage,
    tableFilters,
    setTableFilters,
    setCurrentPage,
    setFilter,
    setOrderColumn,
    orderColumnRef,
    orderDescending,
  } = useClocking();
  
  const [, setLocation] = useLocation();
  
  return (
    <ResponsiveFullWidthContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: TITLE_HEIGHT,
        }}
        mb={8}
      >
        <Title order={2} mb={8}>
          Gestione timbrature
        </Title>
      </Box>
      {!isMobile ?
        <Box sx={{height: `calc(100% - ${TITLE_HEIGHT}px)`}}>
          <DataTable
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            onSearch={(searchValue) => setFilter(searchValue)}
            onSort={(columnRef) => {
              setOrderColumn(columnRef);
            }}
            sortingColumn={orderColumnRef}
            sortingDesc={orderDescending}
            header={[
              {
                accessor: "id",
                visible: false,
              },
              {
                accessor: "dateStart",
                label: "Data inizio",
                visible: true,
              },
              {
                accessor: "dateEnd",
                label: "Data fine",
                visible: true,
              },
              {
                accessor: "customerId",
                label: "Cliente",
                visible: true,
              },
              {
                accessor: "userId",
                label: "Utente",
                visible: true,
              },
            ]}
            data={items}
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            onClick={(id: string) => setLocation(`/clockings/${id}`)}
          />
        </Box> : items.map((item) => (
          <ClockingListItemCardMobile
            key={item.id}
            clocking={item}
            onClick={(id) => setLocation(`/clockings/${id}`)}/>
        ))
      }
    </ResponsiveFullWidthContainer>
  );
};
