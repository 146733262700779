import InspectionMovement, { Month } from "./InspectionMovement";
import React from "react";
import { Grid } from "@mantine/core";

export default function InspectionMovements() {
  return (
    <div>
      <Grid>
        {[...Array(5)].map((_, index) => (
          <Grid.Col xs={3} key={index}>
            <InspectionMovement
              infestante={"Zanzare"}
              costoServizio={100}
              schedule={[
                {
                  numTreatments: 2,
                  month: Month.Gennaio,
                },
                {
                  numTreatments: 1,
                  month: Month.Settembre,
                },
              ]}
              tipologiaServizio={"Disinfestazione"}
              attrezzatura={"Martignani"}
              raccomandazione={"Attenzione ai pesci"}
              specificheTecniche={"blabla"}
              onDelete={() => alert("ciao")}
            />
          </Grid.Col>
        ))}

        <Grid.Col xs={3} key={10}>
          <InspectionMovement
            infestante={"Roditori"}
            costoServizio={150.2}
            schedule={[
              {
                numTreatments: 3,
                month: Month.Febbraio,
              },
            ]}
            tipologiaServizio={"Monitoraggio"}
            attrezzatura={"Atomizzatore"}
            onDelete={() => alert("ciao")}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
}
