import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQueryWithReauth} from "./authApi";
import {type IVehicle} from "../../models/vehicles";
import {IListResponse} from "../../models/api";

export interface IGetVehicleRequest {
  search: string;
  orderBy: string;
  descending: boolean;
  limit: number;
  skip: number;
  columnsFilter: string[];
  columnsFiltersValues: string[];
}

export interface IAddVehicle {
  model: string;
  plate: string;
  number: number;
  owner: string;
  assignedTo: string;
  lastUpdate: string;
  tyresSize: string;
  fuelType: string;
  year: number;
}

export const vehicleApi = createApi({
  reducerPath: "VehicleApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getVehicles: builder.query<IListResponse<IVehicle>, IGetVehicleRequest>({
      query: (args) => {
        const columnFilters = args.columnsFilter.map(
          (x) => `&columnsToFilter=${x}`
        );
        const columnFiltersValues = args.columnsFiltersValues.map(
          (x) => `&columnsFilterValues=${x}`
        );
        
        return {
          url: `/api/vehicle?search=${args.search}&orderBy=${
            args.orderBy
          }&orderDesc=${args.descending}&limit=${args.limit}&skip=${
            args.skip
          }${columnFilters.join("&")}${columnFiltersValues.join("&")}`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    getVehicle: builder.query<IVehicle, string>({
      query: (id) => `/api/Vehicle/${id}`,
      keepUnusedDataFor: 1,
    }),
    updateVehicle: builder.mutation<IVehicle, IVehicle>({
      query: (vehicle) => ({
        url: `/api/vehicle/${vehicle.id}`,
        method: "PUT",
        body: vehicle,
      }),
    }),
    addVehicle: builder.mutation<IVehicle, IAddVehicle>({
      query: (body) => {
        return {
          url: "api/Vehicle",
          method: "POST",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetVehicleQuery,
  useGetVehiclesQuery,
  useUpdateVehicleMutation,
  useAddVehicleMutation,
} = vehicleApi;
