import {Badge, Button, Card, Group, Text} from "@mantine/core"
import React from "react"
import {IconCalendarTime, IconCategory, IconMail, IconPhoneCall, IconUserPlus} from "@tabler/icons-react";
import {IFirstContact} from "../../models/firstContact";

interface Props {
  firstContact: IFirstContact
  onClick: (id: string) => void
}


export const FirstContactItemCardMobile: React.FC<Props> = ({
                                                              firstContact,
                                                              onClick,
                                                            }: Props) => {
  
  return <Card shadow="md" radius='md' withBorder sx={{margin: '20px'}}>
    <Group position="apart" mt="md" mb="xs">
      <Text weight={700} size={18} w={"50%"}>{firstContact.name} {firstContact.surname}</Text>
      <Badge color={firstContact.type === "Preventivo" ? "blue" : "orange"} variant="light" w={"40%"}>
        {firstContact.type}
      </Badge>
    </Group>
    <hr/>
    <Text color="dimmed">
      <div style={{display: "flex"}}>
        <IconPhoneCall style={{marginRight: 16}}/>
        <a href={`telto:${firstContact.phone.replace(" ", "")}`}>{firstContact.phone}</a>
      </div>
      <div style={{display: "flex"}}>
        <IconMail style={{marginRight: 16}}/>
        <a style={{
          overflowWrap: "break-word",
          maxWidth: "80%",
        }} href={`mailto:${firstContact.phone.replace(" ", "")}`}>{firstContact.mail}</a>
      </div>
      <div style={{display: "flex"}}><IconCategory style={{marginRight: 16}}/>Tipologia: {firstContact.type}
      </div>
      <div style={{display: "flex"}}><IconUserPlus style={{marginRight: 16}}/>Creato da: {firstContact.createdBy}
      </div>
      <div style={{display: "flex"}}><IconCalendarTime
        style={{marginRight: 16}}/> Creato il: {new Date(firstContact.createdAt.toString()).toLocaleDateString()}</div>
    </Text>
    
    <Button
      color='blue'
      variant={"light"}
      fullWidth sx={{marginTop: '20px'}}
      radius='md'
      onClick={() => onClick(firstContact.id)}
    >Visualizza</Button>
  </Card>
}