export const itemCategories = [
    {value: "Volatili", label: "Volatili"},
    {value: "Roditori", label: "Roditori"},
    {value: "Zanzare/Blatte", label: "Zanzare/Blatte"},
    {value: "Vespe", label: "Vespe"},
    {value: "Varie", label: "Varie"},
];

export const clockingServices = [
    {value: "PuliziaMeccanizzataCorselloBox", label: "Pulizia Meccanizzata Corsello Box"},
    {value: "PuliziaMeccanizzataSottoportico", label: "Pulizia Meccanizzata Sottoportico"},
    {value: "PuliziaMeccanizzataPartiComuni", label: "Pulizia Meccanizzata Parti Comuni"},
    {value: "PuliziaVetri", label: "Pulizia Vetri"},
    {value: "Altro", label: "Altro"},
]

export const units = [
    {value: "Kg", label: "Kg"},
    {value: "Lt", label: "Lt"},
    {value: "Pz", label: "Pz"},
    {value: "Mt", label: "Mt"},
    {value: "Mq", label: "Mq"},
];

export const operators = [
    {value: "Ali Elfdhili", label: "Ali Elfdhili"},
    {value: "Cristian Cachi Chipata", label: "Cristian Cachi Chipata"},
    {value: "Omar Vaglietti", label: "Omar Vaglietti"},
    {value: "Alex Tironi", label: "Alex Tironi"},
    {value: "Singh", label: "Singh"}
]

export const tracking = {
    maxDistanceFromPositionInMeters: 100
}