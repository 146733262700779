import Keycloak from "keycloak-js";

let keycloakConfig;

if (window.location.hostname === 'gestione.goldservicegroup.it') {
    keycloakConfig = {
        "realm": "goldservice",
        "url": "https://dev.insector.it/auth/",
        "clientId": "frontend",
    };
} else {
    keycloakConfig = {
        "realm": "Insector",
        "url": "https://dev.insector.it/auth/",
        "clientId": "frontend",
    };
}

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;