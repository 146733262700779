import {
  Anchor,
  Autocomplete,
  Breadcrumbs,
  Button,
  Col,
  Divider,
  Grid,
  Select,
  Space,
  Switch,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useState } from "react";
import FullWidthContainer from "../../components/FullWidthContainer";
import { useCustomerSearch } from "../../hooks/useCustomerSearch";
import { useForm } from "@mantine/form";
import { useAddInspectionMutation } from "../../redux/apis/inspectionsApi";

function InspectionCreate() {
  const { customers } =
    useCustomerSearch();

  const [relatedCustomer, setRelatedCustomer] = useState("");

  const [createInspection, { isLoading }] = useAddInspectionMutation();

  const form = useForm({
    initialValues: {
      relatedCustomerId: "",
      infestazioneInAtto: false,
      infestante: "",
      noteSpecie: "",
      noteLimitazioniAccesso: "",
      attenzioniParticolari: "",
    },
  });

  const breadcrumbsItems = [
    { title: "Home", href: "/" },
    { title: "Sopralluoghi", href: "/sopralluoghi" },
    { title: "Create", href: `/sopralluoghi/create` },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <FullWidthContainer>
      <form onSubmit={form.onSubmit((values) => createInspection(values))}>
        <h1>Sopralluogo</h1>
        <Breadcrumbs>{breadcrumbsItems}</Breadcrumbs>
        <Space h="lg" />
        <Divider label={"Dati cliente"} />
        <Space h="lg" />
        <Grid>
          <Col span={6}>
            <Autocomplete
              {...form.getInputProps("relatedCustomerId")}
              value={relatedCustomer}
              onChange={setRelatedCustomer}
              onItemSubmit={(i) => {
                form.setValues({
                  relatedCustomerId: i.customer.id,
                });
              }}
              label="Cliente"
              placeholder="Cerca cliente"
              limit={10}
              data={customers}
            />
          </Col>
        </Grid>

        <Space h="lg" />
        <Divider label={"Sopralluogo"} />
        <Grid>
          <Col span={2}>
            <TextInput label="Numero" disabled />
          </Col>
          <Col span={2}>
            <DatePicker
              label="Data creazione"
              disabled
              renderDay={(date) => <div>{date.getDate()}</div>}
            />
          </Col>
          <Col span={2}>
            <Switch.Group defaultValue={[""]} label="Infestazione in atto">
              <Switch
                onChange={(event) => console.log(event.currentTarget.checked)}
                color="teal"
                size="md"
                {...form.getInputProps("infestazioneInAtto")}
              />
            </Switch.Group>
          </Col>
        </Grid>
        <Space h="lg" />
        <Divider label={"Infestante"} />
        <Grid>
          <Col span={4}>
            <Select
              label="Infestante"
              data={[
                { value: "Zanzare", label: "Zanzare" },
                { value: "Blatte", label: "Blatte" },
                { value: "Mosche", label: "Mosche" },
                { value: "Roditori", label: "Roditori" },
                { value: "Lepidotteri", label: "Lepidotteri" },
                { value: "Coleotteri", label: "Coleotteri" },
                { value: "Microorganismi", label: "Microorganismi" },
                { value: "Cimicideiletti", label: "Cimici dei letti" },
                { value: "Vespe", label: "Vespe" },
                { value: "Zecche", label: "Zecche" },
                { value: "Pulci", label: "Pulci" },
                { value: "Volatili", label: "Volatili" },
              ]}
              {...form.getInputProps("infestante")}
            />
          </Col>
          <Col span={4}>
            <TextInput
              label="Note specie"
              {...form.getInputProps("noteSpecie")}
            />
          </Col>
          <Col span={4}>
            <TextInput
              label="Limitazioni d'accesso"
              {...form.getInputProps("noteLimitazioniAccesso")}
            />
          </Col>
          <Col span={4}>
            <TextInput
              label="Attenzioni particolari"
              {...form.getInputProps("attenzioniParticolari")}
            />
          </Col>
        </Grid>
        <Button type={"submit"}>Salva</Button>
      </form>
    </FullWidthContainer>
  );
}

export default InspectionCreate;
