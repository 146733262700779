import {useDebouncedValue} from "@mantine/hooks";
import {ICustomer} from "../models/customer";
import {useGetCustomersQuery} from "../redux/apis/customerApi";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {setCustomerFilter} from "../redux/slices/inspectionSlice";

type CustomerSearchOption = {
  value: string;
  customer: ICustomer;
};

export const useCustomerSearch = () => {
  const dispatch = useAppDispatch();
  const {customerFilter} = useAppSelector((s) => s.inspections);
  
  const [debouncedFilter] = useDebouncedValue(customerFilter, 200);
  
  const {data} = useGetCustomersQuery({
    search: debouncedFilter,
    orderBy: "DESC",
    descending: false,
    limit: 10,
    skip: 0,
    columnsFilter: [],
    columnsFiltersValues: [],
  })
  
  
  return {
    searchCustomerValue: customerFilter,
    setSearchCustomerValue: (v: string) => dispatch(setCustomerFilter(v)),
    customers: (data?.items || []).map((customer) => {
      return {
        value: `[${customer.erpCode}] - ${customer.description}`,
        customer: customer,
      } as CustomerSearchOption;
    }),
  };
};
