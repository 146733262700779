import {
  ActionIcon,
  Card,
  Center,
  createStyles,
  Group,
  Text,
} from "@mantine/core";
import {
  IconClipboardData,
  IconCurrencyEuro,
  IconTrash,
} from "@tabler/icons-react";
import React from "react";
import { Schedule } from "../../models/proposteTecniche";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },
  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: 5,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[5],
  },
}));

type Movimento = {
  infestante: string;
  tipologiaServizio: string;
  areaInteressata?: string;
  attrezzatura?: string;
  specificheTecniche?: string;
  raccomandazione?: string;
  costoServizio: number;
  note?: string;
  onDelete: () => void;
  schedule: Schedule[];
};

export enum Month {
  Gennaio = 1,
  Febbraio = 2,
  Marzo = 3,
  Aprile = 4,
  Maggio = 5,
  Giugno = 6,
  Luglio = 7,
  Agosto = 8,
  Settembre = 9,
  Ottobre = 10,
  Novembre = 11,
  Dicembre = 12,
}

export const MONTHS_DESCRIPTION = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

export default function InspectionMovement(props: Movimento) {
  const { classes } = useStyles();

  const abbreviatedMonths = MONTHS_DESCRIPTION.map((month) =>
    month.slice(0, 3)
  );

  let numTreatments =
    props.schedule?.reduce((a, s) => a + s.numTreatments, 0) ?? 0;

  const mainInfo = [
    {
      icon: IconCurrencyEuro,
      label: "Costo unitario servizio: ",
      value: props.costoServizio.toLocaleString("it-IT", {
        style: "currency",
        currency: "EUR",
      }),
      display: true,
    },
    {
      icon: IconClipboardData,
      label: "Attrezzatura: ",
      value: props.attrezzatura ?? "",
      display: !!props.attrezzatura,
    },
    {
      icon: IconClipboardData,
      label: "Specifiche tecniche: ",
      value: props.specificheTecniche ?? "",
      display: !!props.specificheTecniche,
    },
    {
      icon: IconClipboardData,
      label: "Raccomandazione: ",
      value: props.raccomandazione ?? "",
      display: !!props.raccomandazione,
    },
  ];

  const features = mainInfo.map((feature) =>
    feature.display ? (
      <Center>
        <feature.icon size="1.05rem" className={classes.icon} stroke={1.5} />
        <Text size="xs">
          <b>{feature.label}</b>
          {feature.value}
        </Text>
      </Center>
    ) : (
      false
    )
  );

  return (
    <div style={{ display: "flex" }}>
      <Card
        withBorder
        radius="md"
        className={classes.card}
        style={{
          borderRight: 0,
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Group position="apart" mt="md">
          <div>
            <Text fw={500}>{props.tipologiaServizio}</Text>
            <Text fz="xs" c="dimmed">
              {props.infestante}
            </Text>
          </div>
        </Group>

        <Card.Section className={classes.section} mt="md">
          <Text fz="sm" c="dimmed" className={classes.label}>
            DETTAGLIO
          </Text>

          <Group spacing={8} mb={-8}>
            {features}
          </Group>
        </Card.Section>

        <Card.Section className={classes.section}>
          <Group
            spacing={30}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <Text fz="xl" fw={700} sx={{ lineHeight: 1 }}>
                {(props.costoServizio * numTreatments).toLocaleString("it-IT", {
                  style: "currency",
                  currency: "EUR",
                })}
              </Text>
              <Text fz="sm" c="dimmed" fw={500} sx={{ lineHeight: 1 }} mt={3}>
                Totale servizio
              </Text>
            </div>

            <ActionIcon color="red" onClick={props.onDelete}>
              <IconTrash />
            </ActionIcon>
          </Group>
        </Card.Section>
      </Card>
      <div
        style={{
          backgroundColor: "white",
          fontSize: "12px",
          display: "flex",
          flexDirection: "column",
          borderBottomRightRadius: "8px",
          border: "1px solid #dee2e6",
          borderTopRightRadius: "8px",
          textTransform: "uppercase",
        }}
      >
        {abbreviatedMonths.map((month, index) => {
          const monthSchedule = getScheduleForSpecificMonth(
            props.schedule ?? [],
            index + 1
          );
          console.log(monthSchedule, props.schedule, index);
          const active = monthSchedule?.numTreatments > 0;

          return (
            <div
              style={{
                borderBottom: "1px solid #dee2e6",
                padding: "3px 2px",
                whiteSpace: "nowrap",
                ...(active ? { backgroundColor: "#07e70759" } : {}),
              }}
            >
              {month} {monthSchedule?.numTreatments}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function getScheduleForSpecificMonth(
  schedule: Schedule[],
  month: number
): Schedule {
  return schedule.find((s) => s.month == month);
}
