import {Button, Card, Group, Text} from "@mantine/core"
import React from "react"
import {IconCar, IconUserBolt} from "@tabler/icons-react";
import {IVehicle} from "../../models/vehicles";

interface Props {
  vehicle: IVehicle
  onClick: (id: string) => void
}


export const VehiclesCardMobile: React.FC<Props> = ({
                                                      vehicle,
                                                      onClick,
                                                    }: Props) => {
  
  return <Card shadow="md" radius='md' withBorder sx={{margin: '20px'}}>
    <Group position="apart" mt="md" mb="xs">
      <Text weight={700} size={18} w={"50%"}>{vehicle.model} {vehicle.plate}</Text>
    </Group>
    <hr/>
    <Text color="dimmed">
      <div style={{display: "flex"}}>
        <IconUserBolt style={{marginRight: 16}}/>
        {vehicle.owner}
      </div>
      <div style={{display: "flex"}}><IconCar style={{marginRight: 16}}/> {vehicle.plate}</div>
    </Text>
    
    <Button
      color='blue'
      variant={"light"}
      fullWidth sx={{marginTop: '20px'}}
      radius='md'
      onClick={() => onClick(vehicle.id)}
    >Visualizza</Button>
  </Card>
}