import {
  ActionIcon,
  Anchor,
  Autocomplete,
  Breadcrumbs,
  Button,
  Col,
  Divider,
  Grid,
  LoadingOverlay,
  Modal,
  NumberInput,
  Select,
  Space,
  Table,
  Textarea,
  TextInput,
} from "@mantine/core";
import React, { useState } from "react";
import FullWidthContainer from "../components/FullWidthContainer";
import { useCustomerSearch } from "../hooks/useCustomerSearch";
import { useForm } from "@mantine/form";
import { useAddProposteTecnicheMutation } from "../redux/apis/proposteTecnicheApi";
import { useDisclosure, useInputState } from "@mantine/hooks";
import { IconCalendarPlus, IconPlus, IconX } from "@tabler/icons-react";
import { showNotification } from "@mantine/notifications";
import { useLocation } from "wouter";
import { Schedule } from "../models/proposteTecniche";
import {
  getScheduleForSpecificMonth,
  Month,
  MONTHS_DESCRIPTION,
} from "./Inspections/InspectionMovement";

interface IMovimenti {
  infestante: string;
  tipologiaServizio: string;
  areaInteressata: string;
  attrezzatura: string;
  specificheTecniche: string;
  raccomandazione: string;
  costoServizio: string;
  note: string;
  schedule: Schedule[];
}

function ProposteTecnicheCreate() {
  const { searchCustomerValue, setSearchCustomerValue, customers } =
    useCustomerSearch();

  const [visible, { toggle }] = useDisclosure(false);

  const [infestante, setInfestante] = useInputState("");
  const [tipologia, setTipologia] = useInputState("");
  const [areaInteressata, setAreaInteressata] = useInputState("");
  const [attrezzatura, setAttrezzatura] = useInputState("");
  const [specificheTecniche, setSpecificheTecniche] = useInputState("");
  const [raccomandazione, setRaccomandazione] = useInputState("");
  const [costoServizio, setCostoServizio] = useInputState(0);
  const [opened, { open, close }] = useDisclosure(false);
  const [relatedCustomer, setRelatedCustomer] = useState("");
  const [schedule, setSchedule] = useInputState<Schedule[]>([]);
  const [note, setNote] = useInputState("");
  const [createProposte, { isLoading }] = useAddProposteTecnicheMutation();
  const [_, setLocation] = useLocation();
  const form = useForm({
    initialValues: {
      customerId: "",
      type: "",
      condizioniPagamento: "",
      condizioniFatturazione: "",
      movimentiProposta: [], //TODO: Refactor this: https://mantine.dev/form/nested/
    },
  });

  const breadcrumbsItems = [
    { title: "Home", href: "/" },
    { title: "Proposte", href: "/proposte" },
    { title: "Create", href: `/proposte/create` },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <>
      <Modal opened={opened} onClose={close} title="Programmazione">
        <Grid>
          {[
            Month.Gennaio,
            Month.Febbraio,
            Month.Marzo,
            Month.Aprile,
            Month.Maggio,
            Month.Giugno,
            Month.Luglio,
            Month.Agosto,
            Month.Settembre,
            Month.Ottobre,
            Month.Novembre,
            Month.Dicembre,
          ].map((index, mese) => {
            const sched = getScheduleForSpecificMonth(schedule, mese);
            return (
              <Col span={3}>
                <NumberInput
                  label={MONTHS_DESCRIPTION[index - 1]}
                  value={sched?.numTreatments ?? 0}
                  onChange={(event) =>
                    setSchedule([
                      ...schedule,
                      { month: mese, numTreatments: event },
                    ])
                  }
                />
              </Col>
            );
          })}
          <Col span={12}>
            <Textarea
              label="Note"
              description="Note per l'intervento"
              placeholder="Inserire note dell'intervento"
              onChange={(event) => setNote(event.currentTarget.value)}
              value={note}
            />
          </Col>
        </Grid>
      </Modal>

      <FullWidthContainer>
        <LoadingOverlay visible={visible} overlayBlur={2} />
        <form
          onSubmit={form.onSubmit((values) => {
            toggle();
            createProposte(values).then(() => {
              showNotification({
                title: "Creazione proposta",
                message: "Proposta creata con successo",
              });

              setLocation("/proposte");
            });
          })}
        >
          <h1>Proposta</h1>
          <Breadcrumbs>{breadcrumbsItems}</Breadcrumbs>
          <Space h="lg" />
          <Divider label={"Dati cliente"} />
          <Space h="lg" />

          <Grid>
            <Col span={6}>
              <Autocomplete
                {...form.getInputProps("customerId")}
                value={relatedCustomer}
                onChange={setRelatedCustomer}
                onItemSubmit={(i) => {
                  form.setValues({
                    customerId: i.customer.id,
                  });
                }}
                label="Cliente"
                placeholder="Cerca cliente"
                limit={10}
                data={customers}
              />
            </Col>
          </Grid>

          <Space h="lg" />
          <Divider label={"Dettaglio proposta"} />
          <Grid>
            <Col span={3}>
              <TextInput label="Tipologia" {...form.getInputProps("type")} />
            </Col>
            <Col span={2}>
              <TextInput
                label="Condizioni Pagamento"
                {...form.getInputProps("condizioniPagamento")}
              />
            </Col>
            <Col span={2}>
              <TextInput
                label="Condizioni Fatturazione"
                {...form.getInputProps("condizioniFatturazione")}
              />
            </Col>
          </Grid>
          <Space h="lg" />

          <Divider label={"Movimenti"} />

          <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
            <thead>
              <tr>
                <th>Infestante</th>
                <th>Tipologia</th>
                <th>Area Interessata</th>
                <th>Attrezzatura</th>
                <th>Specifiche tecniche</th>
                <th>Raccomandazione</th>
                <th>Costo Servizio</th>
                <th>Programmazione</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {form.getInputProps("movimentiProposta").value.map((m, index) => (
                <tr key={index}>
                  <td>{m.infestante}</td>
                  <td>{m.tipologiaServizio}</td>
                  <td>{m.areaInteressata}</td>
                  <td>{m.attrezzatura}</td>
                  <td>{m.specificheTecniche}</td>
                  <td>{m.raccomandazione}</td>
                  <td>{m.costoServizio}</td>
                  <td>test</td>
                  <td>
                    <ActionIcon
                      onClick={() => {
                        const movimentiUpdated = [
                          ...form.getInputProps("movimentiProposta").value,
                        ];
                        movimentiUpdated.splice(index, 1);
                        form
                          .getInputProps("movimentiProposta")
                          .onChange(movimentiUpdated);
                      }}
                    >
                      <IconX />
                    </ActionIcon>
                  </td>
                </tr>
              ))}
              <tr
                key={form.getInputProps("movimentiProposta").value.length + 1}
              >
                <td>
                  <Select
                    placeholder="Selezionare uno"
                    data={[
                      { value: "Zanzare", label: "Zanzare" },
                      { value: "Roditori", label: "Roditori" },
                      { value: "Striscianti", label: "Striscianti" },
                      { value: "Volatili", label: "Volatili" },
                    ]}
                    value={infestante}
                    onChange={setInfestante}
                  />
                </td>
                <td>
                  <Select
                    placeholder="Selezionare uno"
                    data={[
                      { value: "Monitoraggio", label: "Monitoraggio" },
                      { value: "Disinfestazione", label: "Disinfestazione" },
                      { value: "Deblattizzazione", label: "Deblattizzazione" },
                      {
                        value: "Adulticida Zanzare",
                        label: "Adulticida Zanzare",
                      },
                      {
                        value: "Larvicida Zanzare",
                        label: "Larvicida Zanzare",
                      },
                    ]}
                    value={tipologia}
                    onChange={setTipologia}
                  />
                </td>
                <td>
                  <Select
                    placeholder="Selezionare uno"
                    data={[
                      { value: "Aree Comuni", label: "Aree Comuni" },
                      { value: "Parti Private", label: "Parti Private" },
                      { value: "Aree Esterne", label: "Aree Esterne" },
                      { value: "Aree Interne", label: "Aree Interne" },
                      { value: "Caditoie acqua", label: "Caditoie acqua" },
                      { value: "Tombinature", label: "Tombinature" },
                    ]}
                    value={areaInteressata}
                    onChange={setAreaInteressata}
                  />
                </td>
                <td>
                  <Select
                    placeholder="Selezionare uno"
                    data={[
                      {
                        value: "Erogatore di esca",
                        label: "Erogatore di esca",
                      },
                      {
                        value: "Erogatore piastra collante",
                        label: "Erogatore piastra collante",
                      },
                      {
                        value: "Erogatore di cattura",
                        label: "Erogatore di cattura",
                      },
                      { value: "Lampada", label: "Lampada" },
                      {
                        value: "Insetticida liquido",
                        label: "Insetticida liquido",
                      },
                      {
                        value: "Prodotto Larvicida",
                        label: "Prodotto Larvicida",
                      },
                    ]}
                    value={attrezzatura}
                    onChange={setAttrezzatura}
                  />
                </td>
                <td>
                  <TextInput
                    value={specificheTecniche}
                    onChange={(event) =>
                      setSpecificheTecniche(event.currentTarget.value)
                    }
                  />
                </td>
                <td>
                  <TextInput
                    value={raccomandazione}
                    onChange={(event) =>
                      setRaccomandazione(event.currentTarget.value)
                    }
                  />
                </td>
                <td>
                  <NumberInput
                    value={costoServizio}
                    onChange={setCostoServizio}
                  />
                </td>

                <td>
                  <ActionIcon
                    onClick={() => {
                      open();
                    }}
                  >
                    <IconCalendarPlus></IconCalendarPlus>
                  </ActionIcon>
                </td>

                <td>
                  <ActionIcon
                    type={"submit"}
                    onClick={() => {
                      const movimentiUpdated = [
                        ...form.getInputProps("movimentiProposta").value,
                        {
                          infestante: infestante,
                          tipologiaServizio: tipologia,
                          areaInteressata: areaInteressata,
                          attrezzatura: attrezzatura,
                          specificheTecniche: specificheTecniche,
                          raccomandazione: raccomandazione,
                          costoServizio: costoServizio,
                          schedule: schedule,
                          note: note,
                        },
                      ];

                      form
                        .getInputProps("movimentiProposta")
                        .onChange(movimentiUpdated);

                      setInfestante("");
                      setTipologia("");
                      setAreaInteressata("");
                      setAttrezzatura("");
                      setSpecificheTecniche("");
                      setRaccomandazione("");
                      setCostoServizio(0);
                      setSchedule([]);
                      setNote("");
                    }}
                  >
                    <IconPlus />
                  </ActionIcon>
                </td>
              </tr>
            </tbody>
          </Table>
          <Space h="lg" />

          <Button type={"submit"}>Salva</Button>
        </form>
      </FullWidthContainer>
    </>
  );
}

export default ProposteTecnicheCreate;
