import {
  Anchor,
  Box,
  Breadcrumbs,
  Col,
  Divider,
  Grid,
  Space,
  Switch,
  TextInput,
  Title,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React from "react";
import FullWidthContainer from "../../components/FullWidthContainer";
import { useRoute } from "wouter";
import { useGetInspectionQuery } from "../../redux/apis/inspectionsApi";

function InspectionDetail() {
  // @ts-ignore
  const [, { id }] = useRoute("/sopralluoghi/:id");
  const { data } = useGetInspectionQuery(id);

  const breadcrumbsItems = [
    { title: "Home", href: "/" },
    { title: "Sopralluoghi", href: "/sopralluoghi" },
    { title: id, href: `/sopralluoghi/${id}` },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    data && (
      <FullWidthContainer>
        <Title mb={8}>Sopralluogo</Title>
        <Breadcrumbs>{breadcrumbsItems}</Breadcrumbs>
        <Space h="lg" />
        <Divider label={"Dati cliente"} />
        <Space h="lg" />
        <Grid>
          <Col span={2}>
            <TextInput
              label="Cod. Arca"
              value={data.relatedCustomer.erpCode}
              disabled
            />
          </Col>
          <Col span={6}>
            <TextInput
              label="Cliente"
              value={data.relatedCustomer.description}
              disabled
            />
          </Col>
          <Col span={4} />
          <Col span={4}>
            <TextInput
              label="Codice Fiscale"
              value={data.relatedCustomer.cf}
              disabled
            />
          </Col>
          <Col span={4}>
            <TextInput
              label="P.Iva"
              value={data.relatedCustomer.vat}
              disabled
            />
          </Col>
          <Col span={4} />
          <Col span={4}>
            <TextInput
              label="Città"
              value={data.relatedCustomer.city}
              disabled
            />
          </Col>
          <Col span={4}>
            <TextInput
              label="Provincia"
              value={data.relatedCustomer.province}
              disabled
            />
          </Col>
          <Col span={4}>
            <TextInput
              label="Indirizzo"
              value={data.relatedCustomer.address}
              disabled
            />
          </Col>
          <Col span={4}>
            <TextInput
              label="Zona"
              value={data.relatedCustomer.zone}
              disabled
            />
          </Col>
          <Col span={4}>
            <TextInput
              label="Cod. Postale"
              value={data.relatedCustomer.zipCode}
              disabled
            />
          </Col>
          <Col span={12}>
            <TextInput
              label="Note"
              value={data.relatedCustomer.note}
              disabled
            />
          </Col>
        </Grid>

        <Space h="lg" />
        <Divider label={"Sopralluogo"} />
        <Grid>
          <Col span={3}>
            <TextInput label="Numero" value={data.number} disabled />
          </Col>
          <Col span={3}>
            <DatePicker
              value={data.creationDate}
              label="Data creazione"
              renderDay={(date) => <Box>{date.getDate()}</Box>}
              disabled
            />
          </Col>
          <Col span={2}>
            <Switch.Group defaultValue={[""]} label="Infestazione in atto">
              <Switch
                checked={data?.infestazioneInAtto}
                onChange={(event) => console.log(event.currentTarget.checked)}
                color="teal"
                size="md"
              />
            </Switch.Group>
          </Col>
          <Col span={4} />
          <Col span={4}>
            <TextInput label="Infestante" value={data.infestante} disabled />
          </Col>
          <Col span={4}>
            <TextInput label="Note specie" value={data.noteSpecie} disabled />
          </Col>
          <Col span={4}>
            <TextInput
              label="Limitazioni d'accesso"
              value={data.noteLimitazioniAccesso}
              disabled
            />
          </Col>
          <Col span={4}>
            <TextInput
              label="Attenzioni particolari"
              value={data.attenzioniParticolari}
              disabled
            />
          </Col>
        </Grid>
      </FullWidthContainer>
    )
  );
}

export default InspectionDetail;
