import * as React from "react";
import {Container} from "@mantine/core";
import useIsMobile from "../hooks/useIsMobile";

const ResponsiveFullWidthContainer = (props) => {
  const isMobile = useIsMobile();
  return (
    <Container
      fluid
      sx={{
        backgroundColor: "white",
        borderRadius: 6,
        height: isMobile ? "auto" : "80vh",
        padding: 12,
      }}
    >
      {props.children}
    </Container>
  );
};

export default ResponsiveFullWidthContainer;
