import React, {useEffect} from "react";
import {
  Anchor,
  Box,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  Grid,
  Loader,
  LoadingOverlay,
  Select,
  Space,
  TextInput,
  Title,
} from "@mantine/core";
import {useGetVehicleQuery, useUpdateVehicleMutation} from "../../redux/apis/vehicleApi";
import {useForm} from "@mantine/form";
import {showNotification} from "@mantine/notifications";

interface Props {
  id: string;
}

export const VehicleDetail: React.FC<Props> = ({id}) => {
  const {data} = useGetVehicleQuery(id);
  
  const [updateVehicle, {isLoading}] = useUpdateVehicleMutation();
  
  const breadcrumbsItems = [
    {title: "Home", href: "/"},
    {title: "Automezzi", href: "/vehicles"},
    {title: data?.plate, href: `/vehicles/${id}`},
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));
  
  const form = useForm({
    initialValues: {
      model: '',
      plate: '',
      number: 0,
      owner: '',
      assignedTo: '',
      fuelType: '',
      tyresSize: '',
      year: 0,
    },
  });
  
  useEffect(() => {
    form.setValues({
      model: data?.model ?? '',
      plate: data?.plate ?? '',
      number: data?.number ?? 0,
      owner: data?.owner ?? '',
      assignedTo: data?.assignedTo ?? '',
      fuelType: data?.fuelType ?? '',
      tyresSize: data?.tyresSize ?? '',
      year: data?.year ?? 0,
    });
  }, [data]);
  
  return data ? (
    <Container fluid
               sx={{
                 backgroundColor: "white",
                 borderRadius: 6,
                 padding: 12,
                 minHeight: "80vh",
               }}>
      <form onSubmit={form.onSubmit(values => {
        updateVehicle({id: id, ...values}).then(res => {
          // @ts-ignore
          if (res.error) {
            showNotification({
              message: "Qualcosa è andato storto! Contattare l'amministratore.",
              color: "red"
            })
          } else {
            showNotification({
              message: "Elemento aggiornato con successo!"
            })
          }
        })
      })}>
        <LoadingOverlay
          visible={isLoading}
          zIndex={1000}
        />
        <Box>
          <Title mb={16}>Dettaglio automezzo</Title>
          <Breadcrumbs style={{
            flexWrap: "wrap",
          }}>{breadcrumbsItems}</Breadcrumbs>
          <Space h="lg"/>
          <Divider label={"Dati veicolo"}/>
          <Space h="lg"/>
          <Grid>
            <Col xs={12} sm={4}>
              <TextInput label="Modello" {...form.getInputProps("model")} />
            </Col>
            <Col xs={12} sm={4}>
              <TextInput label="Targa" {...form.getInputProps("plate")}/>
            </Col>
            <Col xs={12} sm={4}>
              <TextInput label="Numero" {...form.getInputProps("number")}/>
            </Col>
            <Col xs={12} sm={5}>
              <TextInput label="Proprietario" {...form.getInputProps("owner")}/>
            </Col>
            <Col xs={12} sm={5}>
              <TextInput label="Assegnato a" {...form.getInputProps("assignedTo")}/>
            </Col>
          </Grid>
          <Space h="lg"/>
          <Divider label={"Caratteristiche"}/>
          <Space h="lg"/>
          <Grid>
            <Col xs={12} sm={3}>
              <Select
                label="Carburante"
                placeholder="Selezionare carburante"
                data={[
                  {value: 'diesel', label: 'Diesel'},
                  {value: 'benzina', label: 'Benzina'}
                ]}
                {...form.getInputProps("fuelType")}
              />
            </Col>
            <Col xs={12} sm={3}>
              <TextInput label="Misure pneumatici" {...form.getInputProps("tyresSize")}/>
            </Col>
            <Col xs={12} sm={3}>
              <TextInput label="Anno veicolo" {...form.getInputProps("year")}/>
            </Col>
          </Grid>
        </Box>
        <Space h="xl"/>
        <Button type={"submit"}>Salva</Button>
      </form>
    
    </Container>
  ) : (<Loader color="blue"/>)
}
