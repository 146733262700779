import React from "react";
import {IInventoryMovement} from "../../../models/inspection";
import {Table} from "@mantine/core";


type Props = {
  movements: IInventoryMovement[]
}

export default function Movements({movements}: Props) {
  const orderedMovements = movements.slice().sort((a, b) => {
    let first = new Date(a.creationDate).getTime();
    let second = new Date(b.creationDate).getTime();
    
    return first < second ? 1 : -1;
  })
  return (
    <div style={{overflow: "auto", maxHeight: 350}}>
      <Table striped highlightOnHover withBorder withColumnBorders>
        <thead>
        <th>Eseguito da</th>
        <th>Quantità</th>
        <th>Consegnato a</th>
        <th>Data</th>
        </thead>
        <tbody>
        {
          orderedMovements.map((m) => {
            const date = new Date(m.creationDate)
            return (
              <tr key={m.id}>
                <td>{m.username}</td>
                <td>{m.quantity}</td>
                <td>{m.assignedTo}</td>
                <td>{date.toLocaleDateString()} {date.toLocaleTimeString()}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}