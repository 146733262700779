import React from "react";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  Grid,
  LoadingOverlay,
  Select,
  Space,
  TextInput,
  Title,
} from "@mantine/core";
import {useForm} from "@mantine/form";
import {IAddVehicle, useAddVehicleMutation,} from "../../redux/apis/vehicleApi";
import {showNotification} from "@mantine/notifications";
import {useLocation} from "wouter";

const initialValues: IAddVehicle = {
  model: "",
  assignedTo: "",
  fuelType: "",
  number: 0,
  owner: "",
  plate: "",
  tyresSize: "",
  year: 0,
  lastUpdate: new Date().toISOString(),
};

export const VehicleCreate: React.FC = () => {
  const [addVehicle, {isLoading}] = useAddVehicleMutation();
  const [submitting, isSubmitting] = React.useState(false);
  const [, setLocation] = useLocation();
  
  const breadcrumbsItems = [
    {title: "Home", href: "/"},
    {title: "Veicoli", href: "/vehicles"},
    {title: "Aggiungi", href: `/vehicles/add`},
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ));
  
  const form = useForm({initialValues});
  
  return (
    <Container fluid
               sx={{
                 backgroundColor: "white",
                 borderRadius: 6,
                 padding: 12,
                 minHeight: "80vh",
               }}>
      <LoadingOverlay visible={isLoading} overlayBlur={2}/>
      <form
        onSubmit={form.onSubmit((values) => {
          isSubmitting(true)
          
          addVehicle(values).then(() => {
            showNotification({
              title: "Veicolo aggiunto",
              message: "Veicolo aggiunto alla flotta con successo",
            });
          });
          setLocation("/vehicles");
        })}
      >
        <Title>Aggiungi un veicolo</Title>
        <Space h="md"/>
        <Breadcrumbs>{breadcrumbsItems}</Breadcrumbs>
        <Space h="lg"/>
        <Divider label="Dati veicolo"/>
        <Space h="lg"/>
        <Grid>
          <Col xs={12} sm={4}>
            <TextInput label="Modello" {...form.getInputProps("model")} />
          </Col>
          <Col xs={12} sm={4}>
            <TextInput label="Targa" {...form.getInputProps("plate")} required/>
          </Col>
          <Col xs={12} sm={4}>
            <TextInput label="Numero" {...form.getInputProps("number")} />
          </Col>
          <Col xs={12} sm={5}>
            <TextInput label="Proprietario" {...form.getInputProps("owner")} required/>
          </Col>
          <Col xs={12} sm={5}>
            <TextInput
              label="Assegnato a"
              {...form.getInputProps("assignedTo")}
              required
            />
          </Col>
        </Grid>
        <Space h="lg"/>
        <Divider label={"Caratteristiche"}/>
        <Space h="lg"/>
        <Grid>
          <Col xs={12} sm={3}>
            <Select
              label="Carburante"
              placeholder="Selezionare carburante"
              data={[
                {value: "diesel", label: "Diesel"},
                {value: "benzina", label: "Benzina"},
              ]}
              {...form.getInputProps("fuelType")}
            />
          </Col>
          <Col xs={12} sm={3}>
            <TextInput
              label="Misure pneumatici"
              {...form.getInputProps("tyresSize")}
            />
          </Col>
          <Col xs={12} sm={3}>
            <TextInput label="Anno veicolo" {...form.getInputProps("year")} />
          </Col>
        </Grid>
        <Space h="lg"/>
        <Button type="submit" disabled={submitting}>Salva</Button>
      </form>
    </Container>
  );
};
