import { useEffect, useState } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { TableFilter } from "../../components/table/DataTable";
import { IInspection } from "../../models/inspection";
import { useGetInspectionsQuery } from "../../redux/apis/inspectionsApi";

const DEFAULT_PAGE_LIMIT = 100;

const columnNameCapitalized = (columnName: string): string => {
  return columnName.charAt(0).toUpperCase() + columnName.slice(1);
};

export const useSopralluoghi = () => {
  const [sopralluoghi, setSopralluoghi] = useState<IInspection[]>([]);
  const [pages, setPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>("");
  const [orderColumnRef, setOrderColumnRef] = useState<string>("");
  const [orderDescending, setOrderDescending] = useState<boolean>(false);
  const [debouncedFilter] = useDebouncedValue(filter, 200);
  const [tableFilters, setTableFilters] = useState<TableFilter[]>([]);

  const { data } = useGetInspectionsQuery({
    search: debouncedFilter,
    orderBy: columnNameCapitalized(orderColumnRef),
    descending: orderDescending,
    limit: DEFAULT_PAGE_LIMIT,
    skip: (currentPage - 1) * DEFAULT_PAGE_LIMIT,
    columnsFilter: tableFilters.map((x) => columnNameCapitalized(x.columnRef)),
    columnsFiltersValues: tableFilters.map((x) => x.value),
  });

  const setTableFiltersByPrev = (filter: TableFilter) =>
    setTableFilters((prev) => {
      const filters = prev.filter(
        (prevFilter) => prevFilter.columnRef !== filter.columnRef
      );

      if (!filter.value) return filters;

      return [...filters, filter];
    });

  useEffect(() => {
    if (data) {
      setSopralluoghi(data.items);
      setPages(Math.ceil(data.length / DEFAULT_PAGE_LIMIT));
    }
  }, [data]);

  const setOrderColumn = (orderColumn: string) => {
    const alreadySelected =
      orderColumn.toLowerCase() === orderColumnRef.toLowerCase();

    setOrderDescending((prev) => prev !== alreadySelected);
    setOrderColumnRef(orderColumn);
  };

  return {
    sopralluoghi,
    pages,
    currentPage,
    tableFilters,
    setTableFilters: setTableFiltersByPrev,
    setCurrentPage,
    setFilter,
    setOrderColumn,
    orderColumnRef,
    orderDescending,
  };
};
