import React, {useEffect, useState} from "react";
import {useLazyGetInventoryQuery} from "../../redux/apis/inventoryApi";
import ItemScanner from "../ItemScanner";
import {Box, Button, LoadingOverlay, Modal, Title} from "@mantine/core";
import UploadItem from "./components/Upload_DownloadItem";
import {useKeycloak} from "@react-keycloak/web";
import {IconBarcode, IconFaceIdError} from "@tabler/icons-react";

const Download: React.FC = () => {
  const [barcode, setBarcode] = useState<string>("");
  const [downloadOpened, setDownloadOpen] = useState<boolean>(false);
  const [alertOpened, setAlertOpen] = useState<boolean>(false);
  
  const [username, setUsername] = useState<string>("")
  const {keycloak} = useKeycloak();
  const [trigger, result] = useLazyGetInventoryQuery();
  
  useEffect(() => {
    keycloak.loadUserProfile().then((u) => setUsername(u.username));
  }, [keycloak]);
  
  useEffect(() => {
    if (barcode && barcode !== "")
      trigger(barcode);
  }, [barcode, trigger])
  
  
  useEffect(() => {
    if (result.isError) {
      setAlertOpen(true);
    } else if (result.isSuccess && result.status !== "pending") {
      setDownloadOpen(true);
    }
  }, [result]);
  
  const resetBarcode = (): void => {
    setBarcode("");
  };
  
  return (
    <>
      {!barcode && (
        <ItemScanner
          title={"Scarica da inventario ⬇️"}
          setResult={(bc) => {
            setBarcode(bc);
          }}
        />
      )}
      
      {result.isFetching && <LoadingOverlay visible={true} overlayBlur={2}/>}
      
      <Modal
        opened={alertOpened}
        onClose={() => {
          setAlertOpen(!alertOpened);
          resetBarcode();
        }}
      >
        <div style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 20
        }}>
          <IconFaceIdError color={"red"} size={"80"}/>
        </div>
        <Title order={3} mb={8} sx={{textAlign: "center"}}>
          Elemento non Trovato!
        </Title>
        <Box sx={{marginBottom: 40, textAlign: "center"}}>
          <div><b>Barcode scansionato:</b> {barcode}</div>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "5% 0%",
          }}
        >
          <Button
            variant="filled"
            onClick={() => {
              setAlertOpen(!alertOpened);
              resetBarcode();
            }}
          >
            <span style={{marginRight: 8, display: "flex"}}><IconBarcode size={16}/></span>
            RISCANSIONA
          </Button>
        </Box>
      </Modal>
      
      {downloadOpened && <UploadItem
          isOpen={downloadOpened}
          formProps={{
            id: result.data?.id,
            name: result.data?.name,
            barcode: barcode,
            actualQuantity: result.data?.quantity,
            username: username,
          }}
          setIsOpen={setDownloadOpen}
          resetBarcode={resetBarcode}
          download
      />}
    </>
  );
};
export default Download;
