import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'


interface UserState {
  name: string
  token: string
}

const initialState = {token: ""} as UserState

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsername(state, action: PayloadAction<string>) {
      state.name = action.payload
    },
    tokenReceived(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    loggedOut(state) {
      state.token = ""
    }
  },
})

export const {tokenReceived, loggedOut} = userSlice.actions
export default userSlice.reducer