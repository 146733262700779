import * as React from "react";
import FullWidthContainer from "../components/FullWidthContainer";
import {DataTable} from "../components/table/DataTable";
import {Box, Button, Title} from "@mantine/core";
import {useLocation} from "wouter";
import {useProposteTecniche} from "../hooks/useProposteTecniche";

const TITLE_HEIGHT = 42;

const ProposteTecniche = () => {
  const {
    proposteTecniche,
    pages,
    currentPage,
    tableFilters,
    setTableFilters,
    setCurrentPage,
    setFilter,
    setOrderColumn,
    orderColumnRef,
    orderDescending,
  } = useProposteTecniche();
  
  const [, setLocation] = useLocation();
  
  return (
    <FullWidthContainer style={{
      height: "100vh"
    }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: TITLE_HEIGHT,
        }}
        mb={8}
      >
        <Title order={1}>Proposte Tecniche</Title>
        <Button onClick={() => setLocation(`/proposte/create`)}>Crea</Button>
      </Box>
      <Box sx={{height: `calc(100% - ${TITLE_HEIGHT}px)`}}>
        <DataTable
          tableFilters={tableFilters}
          setTableFilters={setTableFilters}
          onSearch={(searchValue) => setFilter(searchValue)}
          onSort={(columnRef) => {
            setOrderColumn(columnRef);
          }}
          sortingColumn={orderColumnRef}
          sortingDesc={orderDescending}
          header={[
            {
              accessor: "id",
              visible: false,
            },
            {
              accessor: "number",
              visible: true,
              label: "Numero",
            },
            {
              accessor: "customerDescription",
              visible: true,
              label: "Cliente",
            },
            {
              accessor: "creationDate",
              visible: true,
              label: "Data creazione",
            },
            {
              accessor: "type",
              visible: true,
              label: "Tipo",
            },
          ]}
          data={proposteTecniche ?? []}
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          onClick={(id: string) => setLocation(`/proposte/${id}`)}
        />
      </Box>
    </FullWidthContainer>
  );
};

export default ProposteTecniche;
