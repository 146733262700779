import {Group, Text, ThemeIcon, UnstyledButton} from "@mantine/core";
import * as React from "react";
import {useLocation, useRoute} from "wouter";

type MenuItemProps = {
  label: string;
  link: string;
  icon: React.ReactElement;
  haveSubItem?: boolean;
  subItems?: MenuItemProps[];
};

const MenuItem = (props: MenuItemProps) => {
  const [location, setLocation] = useLocation();
  const [match] = useRoute(props.link);
  const [isClick, setIsClick] = React.useState<boolean>(false);
  return (
    <>
      <UnstyledButton
        onClick={() => {
          setLocation(props.link);
          setIsClick(!isClick);
        }}
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.black,
          backgroundColor: match ? theme.colors.gray[0] : "transparent",
          "&:hover": {
            backgroundColor: theme.colors.gray[0],
          },
        })}
      >
        <Group>
          <ThemeIcon variant="light" size="sm" color="gray">
            {props.icon}
          </ThemeIcon>
          <Text size="sm">{props.label}</Text>
        </Group>
      </UnstyledButton>
      {(props.haveSubItem) || props.link === "#" ? (
        props.subItems.map((item, i) => {
          return (
            <UnstyledButton
              key={i}
              onClick={() => {
                setLocation(item.link);
              }}
              sx={(theme) => ({
                display: "block",
                marginInlineStart: 30,
                width: "90%",
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                color: theme.black,
                backgroundColor: item.link === location ? theme.colors.gray[0] : "transparent",
                "&:hover": {
                  backgroundColor: theme.colors.gray[0],
                },
              })}
            >
              <Group>
                <ThemeIcon variant="light" size="sm" color="gray">
                  {item.icon}
                </ThemeIcon>
                <Text size="sm">{item.label}</Text>
              </Group>
            </UnstyledButton>
          );
        })
      ) : (
        <UnstyledButton
          sx={(theme) => ({
            display: "none",
            width: "90%",
            marginLeft: 30,
            padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.black,
            backgroundColor: match ? theme.colors.gray[0] : "transparent",
            "&:hover": {
              backgroundColor: theme.colors.gray[0],
            },
          })}
        ></UnstyledButton>
      )}
    </>
  );
};

export default MenuItem;
