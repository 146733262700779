import React, {useEffect, useState} from "react";
import {Box, Button, createStyles, Pagination, ScrollArea, Table, Text, TextInput,} from "@mantine/core";
import {IconSearch} from "@tabler/icons-react";
import {TableHeader} from "./TableHeader";
import lodash from "lodash";

export const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },
  
  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
  icon: {
    width: 32,
  },
}));

interface Header {
  accessor: string;
  label?: string;
  visible: boolean;
}

interface TableSortProps {
  data: object[];
  pages: number;
  tableFilters: TableFilter[];
  setTableFilters: (filter: TableFilter) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  header: Header[];
  onSearch: (searchFilter: string) => void;
  onSort: (columnRef: string) => void;
  sortingColumn?: string;
  sortingDesc?: boolean;
  onClick?: (id: string) => void;
}

export type TableFilter = {
  value: string;
  columnRef: string;
};

export function DataTable({
                            data,
                            pages,
                            tableFilters,
                            setTableFilters,
                            currentPage,
                            setCurrentPage,
                            header,
                            onSearch,
                            onSort,
                            sortingColumn,
                            sortingDesc,
                            onClick,
                          }: TableSortProps) {
  const [search, setSearch] = useState("");
  const [visibleHeader, setVisibleHeader] = useState(header);
  
  useEffect(() => {
    setVisibleHeader(header.filter((h) => h.visible));
  }, [header]);
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.currentTarget;
    setSearch(value);
    onSearch(value);
  };
  
  const rows = data.map((row) => (
    <tr key={row["id"]}>
      {onClick && (
        <td key={`${row["id"]}-action`}>
          <Button onClick={() => onClick(row["id"])}>Visualizza</Button>
        </td>
      )}
      {visibleHeader.map((h) => {
        let value = lodash.get(row, h.accessor);
        
        if (typeof value == "boolean") {
          value = value ? "Si" : "No";
        }
        
        return <td key={`${row["id"]}-${h.accessor}`} style={{whiteSpace: "nowrap"}}>{value}</td>;
      })}
    </tr>
  ));
  
  return (
    <Box sx={{height: "100%"}}>
      <TextInput
        placeholder="Ricerca"
        mb="md"
        icon={<IconSearch size={14} stroke={1.5}/>}
        value={search}
        onChange={handleSearchChange}
      />
      <ScrollArea sx={{height: "calc(100% - 52px)"}}>
        <Table
          horizontalSpacing="md"
          verticalSpacing="xs"
          sx={{
            tableLayout: "fixed",
            minWidth: "100%",
            width: "auto",
            maxWidth: "100%",
            overflow: "scroll",
          }}
        >
          <thead
            style={{
              position: "sticky",
              top: 0,
              background: "white",
              boxShadow: "0px 0px 4px 4px rgb(0 0 0 / 1%)",
              zIndex: 99
            }}
          >
          <tr>
            {onClick && <TableHeader key={`header-action`}>#</TableHeader>}
            {visibleHeader.map((h) => {
              const filterValue =
                tableFilters.find((x) => x.columnRef === h.accessor)?.value ??
                "";
              
              return (
                <TableHeader
                  key={h.accessor}
                  onSort={() => onSort(h.accessor)}
                  sorted={
                    h.accessor?.toLowerCase() ===
                    (sortingColumn?.toLowerCase() ?? "")
                  }
                  reversed={sortingDesc}
                  columnRef={h.accessor}
                  filter={filterValue}
                  onFilter={(value: string, columnRef: string) => {
                    setTableFilters({value: value, columnRef: columnRef});
                  }}
                >
                  {h.label}
                </TableHeader>
              );
            })}
          </tr>
          </thead>
          <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td colSpan={visibleHeader.length}>
                <Text weight={500} align="center">
                  Nothing found
                </Text>
              </td>
            </tr>
          )}
          </tbody>
        </Table>
        <Pagination
          total={pages}
          page={currentPage}
          onChange={setCurrentPage}
          withEdges
          color={"green"}
          size={"sm"}
          style={{
            position: "sticky",
            bottom: 0,
            background: "white",
            width: "100%",
            padding: "20px 10px",
          }}
        />
      </ScrollArea>
    </Box>
  );
}
