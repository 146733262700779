import React, {useState} from "react";
import {ActionIcon, Box, Button, Popover, TextInput, Title} from "@mantine/core";
import {DataTable} from "../components/table/DataTable";
import {useInventory} from "../hooks/useInventory";
import {useLocation} from "wouter";
import {InventoryItemCardMobile} from "../components/inventoryCard/InventoryItemCardMobile";
import ResponsiveFullWidthContainer from "../components/ResponsiveFullWidthContainer";
import {IconArrowsSort, IconSearch, IconSortAscending, IconSortDescending} from "@tabler/icons-react";
import useIsMobile from "../hooks/useIsMobile";

const TITLE_HEIGHT = 42;

const Warehouse: React.FC = () => {
  const isMobile = useIsMobile();
  
  const {
    inventoryItems,
    pages,
    currentPage,
    tableFilters,
    setTableFilters,
    setCurrentPage,
    filter,
    setFilter,
    setOrderColumn,
    orderColumnRef,
    orderDescending,
  } = useInventory(isMobile);
  
  const [, setLocation] = useLocation();
  const [showOrderPopover, setShowOrderPopover] = useState<boolean>(false);
  
  const morePages = pages >= currentPage + 1;
  const columns = [
    {
      accessor: "id",
      visible: false,
    },
    {
      accessor: "barcode",
      visible: true,
      label: "Barcode"
    },
    {
      accessor: "name",
      visible: true,
      label: "Nome prodotto"
    },
    {
      accessor: "quantity",
      visible: true,
      label: "Qnt"
    },
    {
      accessor: "lastUpdate",
      visible: true,
      label: "Ultimo aggiornamento"
    }
  ];
  return (
    <ResponsiveFullWidthContainer>
      <Title order={1} mb={8} sx={{height: TITLE_HEIGHT}}>
        Inventario
      </Title>
      {
        !isMobile
          ? <Box sx={{height: `calc(100% - ${TITLE_HEIGHT}px)`}}>
            <DataTable
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
              onSearch={(searchValue) => setFilter(searchValue)}
              onSort={(columnRef) => {
                setOrderColumn(columnRef);
              }}
              sortingColumn={orderColumnRef}
              sortingDesc={orderDescending}
              header={columns}
              data={inventoryItems}
              pages={pages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              onClick={(id: string) => setLocation(`/warehouse/item/${id}`)}
            /></Box>
          : <Box sx={{marginBottom: TITLE_HEIGHT + 50}}>
            <div style={{
              display: "flex",
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <IconSearch/>
              <TextInput
                w={"80%"}
                placeholder='Cerca'
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                sx={{margin: 8}}
              />
              <Popover opened={showOrderPopover} onChange={setShowOrderPopover}>
                <Popover.Target>
                  <ActionIcon variant={"filled"} color={"blue"}><IconArrowsSort
                    onClick={() => setShowOrderPopover((o) => !o)}/></ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <div>
                    {columns.map(c => {
                      const isOrdering = orderColumnRef === c.accessor
                      return c.visible && (
                        <div style={{display: "flex", marginBottom: 8}} onClick={() => setOrderColumn(c.accessor)}>
                          <ActionIcon>
                            {isOrdering && (orderDescending ? <IconSortDescending/> : <IconSortAscending/>)}
                          </ActionIcon>
                          {c.label}
                        </div>
                      )
                    })}
                  </div>
                </Popover.Dropdown>
              </Popover>
            </div>
            {
              inventoryItems.map((item) =>
                <InventoryItemCardMobile
                  key={item.barcode}
                  productName={item.name}
                  barcode={item.barcode}
                  quantity={item.quantity}
                  shelf={item.rack}
                  lastUpdate={item.lastUpdate}
                  capacityThreshold={item.capacityThreshold}
                  onClick={(id: string) => setLocation(`/warehouse/item/${id}`)}
                />
              )
            }
            {
              morePages && (
                <Button color='blue'
                        variant={"outline"}
                        fullWidth
                        radius='md'
                        onClick={() => setCurrentPage(currentPage + 1)}
                >Carica altri</Button>)
            }
          </Box>
      }
    </ResponsiveFullWidthContainer>
  )
};

export default Warehouse;
