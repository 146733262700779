import {
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  Grid,
  LoadingOverlay,
  Select,
  Space,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import {useAddFirstContactMutation} from "../../redux/apis/firstContactApi";
import {useKeycloak} from "@react-keycloak/web";
import {useLocation} from "wouter";
import {showNotification} from "@mantine/notifications";
import {openDB} from "idb";
import {useNetwork} from "@mantine/hooks";

async function addFirstContactsToStore(values) {
  await openDB('ContactDatabase', 2, {
    upgrade(db) {
      const store = db.createObjectStore('ContactStore', {
        keyPath: 'id',
        autoIncrement: true,
      });
      store.createIndex('createdBy', 'createdBy');
    },
  }).then(async (db) => {
    const tx = db.transaction('ContactStore', 'readwrite');
    
    await Promise.all([
      tx.store.add(values),
      tx.done
    ]);
  });
}

function FirstContactCreate() {
  const [username, setUsername] = useState<string>("")
  const {keycloak} = useKeycloak();
  const networkStatus = useNetwork();
  
  useEffect(() => {
    keycloak.loadUserProfile().then((u) => setUsername(u.username));
  }, []);
  
  const [_, setLocation] = useLocation();
  
  const [createFirstContact, {isLoading}] = useAddFirstContactMutation();
  const [submitting, isSubmitting] = React.useState(false);
  
  const form = useForm({
    initialValues: {
      number: 0,
      name: "",
      surname: "",
      address: "",
      cap: "",
      phone: "",
      mail: "",
      service: "",
      type: "",
      notes: "",
      createdBy: username,
      createdAt: new Date()
    },
  });
  
  const breadcrumbsItems = [
    {title: "Home", href: "/"},
    {title: "Moduli primo contatto", href: "/firstcontact"},
    {title: "Creazione", href: `/firstcontact/create`},
  ].map((item, index) => (
    <div onClick={() => setLocation(item.href)} style={{color: "blue", cursor: "pointer"}}
         key={index}>
      {item.title}
    </div>
  ));
  
  function afterContactCreation(res) {
    // @ts-ignore
    if (res.error) {
      showNotification({
        message: "Qualcosa è andato storto! Contattare l'amministratore.",
        color: "red"
      })
    } else {
      showNotification({
        message: "Elemento creato con successo!"
      })
      setLocation("/firstcontact");
    }
  }
  
  const onSubmit = async (values) => {
    isSubmitting(true);
    if (!networkStatus.online) {
      await addFirstContactsToStore({...values, createdBy: username});
      isSubmitting(false);
      showNotification({
        message: "Il primo contatto verrà salvato appena la connessione sarà ripristinata!",
        color: "yellow"
      })
      return;
    }
    createFirstContact({...values, createdBy: username}).then(afterContactCreation);
  };
  
  useEffect(() => {
    if (!networkStatus.online) return;
    
    openDB('ContactDatabase', 2).then((db) => {
      db.getAll('ContactStore').then((contacts) => {
        if (contacts.length === 0) return;
        contacts.forEach((c) => {
          createFirstContact({...c, id: undefined}).then((res) => {
            // @ts-ignore
            if (!res.error) db.delete('ContactStore', c.id)
            else
              showNotification({
                message: "Qualcosa è andato storto durante la sincronizzazione dei dati offline! Contattare l'amministratore.",
                color: "red"
              })
          })
        })
      });
    });
  }, [networkStatus.online]);
  
  return (
    <Container fluid
               sx={{
                 backgroundColor: "white",
                 borderRadius: 6,
                 padding: 12,
                 minHeight: "80vh",
               }}>
      <LoadingOverlay visible={isLoading} overlayBlur={2}/>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Title mb={16}>Creazione modulo primo contatto</Title>
        <Breadcrumbs style={{
          flexWrap: "wrap",
        }}>{breadcrumbsItems}</Breadcrumbs>
        <Space h="lg"/>
        <Divider label={"Dati contatto"}/>
        <Grid>
          <Col sm={3} xs={12}>
            <Select data={[
              {value: "Preventivo", label: "Preventivo"},
              {value: "Intervento", label: "Intervento"},
            ]} label="Tipo contatto" required  {...form.getInputProps("type")}/>
          </Col>
          <Col sm={3} xs={12}>
            <TextInput label="Nome" required {...form.getInputProps("name")}/>
          </Col>
          <Col sm={3} xs={12}>
            <TextInput label="Cognome" required {...form.getInputProps("surname")}/>
          </Col>
        </Grid>
        <Space h="xl"/>
        <Divider label={"Informazioni di contatto"}/>
        <Grid>
          <Col sm={3} xs={12}>
            <TextInput label="Indirizzo" {...form.getInputProps("address")}/>
          </Col>
          <Col sm={2} xs={12}>
            <TextInput label="Cap" required {...form.getInputProps("cap")}/>
          </Col>
          <Col sm={3} xs={12}>
            <TextInput label="Telefono" required {...form.getInputProps("phone")}/>
          </Col>
          <Col sm={3} xs={12}>
            <TextInput label="Email" {...form.getInputProps("mail")}/>
          </Col>
        </Grid>
        <Space h="xl"/>
        <Divider label={"Servizio richiesto"}/>
        <Grid>
          <Col sm={3} xs={12}>
            <Select data={[
              {value: "DisinfestazioneZanzare", label: "Disinfestazione Zanzare"},
              {value: "Derattizzazione / Monitoraggio roditori", label: "Derattizzazione / Monitoraggio roditori"},
              {value: "Vespe / Calabroni", label: "Vespe / Calabroni"},
              {value: "Deblattizzazione (Blatta orientalis)", label: "Deblattizzazione (Blatta orientalis)"},
              {value: "Deblattizzazione (Blattella germanica)", label: "Deblattizzazione (Blattella germanica)"},
              {value: "Allontanamento volatili", label: "Allontanamento volatili"},
              {value: "Altro", label: "Altro"},
            ]} label="Servizio"  {...form.getInputProps("service")}/>
          </Col>
        </Grid>
        
        <Space h="xl"/>
        <Divider label={"Informazioni aggiuntive"}/>
        <Grid>
          <Col sm={6} xs={12}>
            <Textarea label="Note"  {...form.getInputProps("notes")}/>
          </Col>
        </Grid>
        <Grid>
          <Col sm={3} xs={12}>
            <TextInput label="Creato da" disabled value={username}/>
          </Col>
        </Grid>
        <Space h="xl"/>
        <Button type={"submit"} disabled={submitting}>Salva</Button>
      </form>
    </Container>
  );
}

export default FirstContactCreate;