import {useEffect, useState} from 'react';

export enum Company {
  GoldService = 'GoldService',
  Insector = 'Insector'
}

function useGetCompany() {
  const [company, setCompany] = useState<Company | null>(null);
  
  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname === 'gestione.goldservicegroup.it') {
      setCompany(Company.GoldService);
    } else {
      setCompany(Company.Insector);
    }
  }, []);
  
  return company;
}

export default useGetCompany;