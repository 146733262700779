import * as React from "react";
import WidgetThresholdReached from "./WidgetThresholdReached";
import {useGetInventoryWithReachedThresholdQuery} from "../../redux/apis/inventoryApi";

const InventoryDashboard = () => {
  const {data: reachedThresholdItems} = useGetInventoryWithReachedThresholdQuery();
  
  return <>
    <WidgetThresholdReached reachedThresholdItems={reachedThresholdItems}/>
  </>;
}

export default InventoryDashboard;
