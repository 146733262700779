import {
  Breadcrumbs,
  Button,
  Col,
  Container,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  Space,
  Switch,
  Textarea,
  TextInput,
} from "@mantine/core";
import React, {useEffect} from "react";
import {useForm} from "@mantine/form";
import {useGetFirstContactQuery, useUpdateFirstContactMutation} from "../../redux/apis/firstContactApi";
import {useLocation, useRoute} from "wouter";
import {showNotification} from "@mantine/notifications";
import {useDisclosure} from '@mantine/hooks';
import {IconCheck, IconX} from "@tabler/icons-react";

function FirstContactDetail() {
  // @ts-ignore
  const [, {id}] = useRoute("/firstcontact/:id");
  const {data} = useGetFirstContactQuery(id);
  const [loadingData, setLoadingData] = React.useState(true);
  const [_, setLocation] = useLocation();
  const [editMode, editModeHandler] = useDisclosure(false);
  const [submitting, isSubmitting] = React.useState(false);
  
  const [updateFirstContact, {isLoading}] = useUpdateFirstContactMutation();
  
  const form = useForm({
    initialValues: {
      number: 0,
      name: "",
      surname: "",
      address: "",
      cap: "",
      phone: "",
      mail: "",
      service: "",
      type: "",
      notes: "",
      createdBy: "",
      createdAt: new Date()
    },
  });
  
  useEffect(() => {
    setLoadingData(!data);
    form.setValues({
      number: data?.number,
      name: data?.name,
      surname: data?.surname,
      address: data?.address,
      cap: data?.cap,
      phone: data?.phone,
      mail: data?.mail,
      service: data?.service,
      type: data?.type,
      notes: data?.notes,
      createdBy: data?.createdBy,
      createdAt: data?.createdAt
    });
  }, [data])
  
  const breadcrumbsItems = [
    {title: "Home", href: "/"},
    {title: "Moduli primo contatto", href: "/firstcontact"},
    {title: `${data?.name} ${data?.surname}`, href: `/firstcontact/${id}`},
  ].map((item, index) => (
    <div onClick={() => setLocation(item.href)} style={{color: "blue", cursor: "pointer"}}
         key={index}>
      {item.title}
    </div>
  ));




  return (
    <>
      <LoadingOverlay visible={isLoading || loadingData} overlayBlur={2}/>
      <Container fluid
                 sx={{
                   backgroundColor: "white",
                   borderRadius: 6,
                   padding: 12,
                   minHeight: "80vh",
                 }}>
        
        <form onSubmit={form.onSubmit((values) => {
          isSubmitting(true)
          updateFirstContact({...values, id: id, number: data?.number})
            .then((res) => {
              // @ts-ignore
              if (res.error) {
                showNotification({
                  message: "Qualcosa è andato storto! Contattare l'amministratore.",
                  color: "red"
                })
              } else {
                setLocation("/firstcontact");
                showNotification({
                  message: "Elemento aggiornato con successo!"
                })
              }
            })
        })}>
          <Group style={{
            margin: "10px 0",
          }}>
            <Switch
              checked={editMode}
              onChange={() => editModeHandler.toggle()}
              color="teal"
              size="md"
              label="Modifica abilitata"
              thumbIcon={
                editMode ? (
                  <IconCheck size={12} color={"teal"} stroke={3}/>
                ) : (
                  <IconX size={12} color={"#40E0D0"} stroke={3}/>
                )
              }
            />
          </Group>
          <h1 style={{
            lineHeight: "32px",
          }}>Modulo primo contatto</h1>
          <Breadcrumbs style={{
            flexWrap: "wrap",
          }}>{breadcrumbsItems}</Breadcrumbs>
          <Space h="lg"/>
          <Divider label={"Dati contatto"}/>
          <Grid>
            <Col sm={2} xs={12}>
              <TextInput label="#" disabled required value={data?.number}/>
            </Col>
            <Col sm={3} xs={12}>
              <Select data={[
                {value: "Preventivo", label: "Preventivo"},
                {value: "Intervento", label: "Intervento"},
              ]} label="Tipo contatto" required  {...form.getInputProps("type")} disabled={!editMode}/>
            </Col>
            <Col sm={3} xs={12}>
              <TextInput label="Nome" required {...form.getInputProps("name")} disabled={!editMode}/>
            </Col>
            <Col sm={3} xs={12}>
              <TextInput label="Cognome" required {...form.getInputProps("surname")} disabled={!editMode}/>
            </Col>
          </Grid>
          <Space h="xl"/>
          <Divider label={"Informazioni di contatto"}/>
          <Grid>
            <Col sm={3} xs={12}>
              <TextInput label="Indirizzo" {...form.getInputProps("address")} disabled={!editMode}/>
            </Col>
            <Col sm={2} xs={12}>
              <TextInput label="Cap" required {...form.getInputProps("cap")} disabled={!editMode}/>
            </Col>
            <Col sm={3} xs={12}>
              <TextInput label="Telefono" required {...form.getInputProps("phone")} disabled={!editMode}/>
            </Col>
            <Col sm={3} xs={12}>
              <TextInput label="Email" {...form.getInputProps("mail")} disabled={!editMode}/>
            </Col>
          </Grid>
          <Space h="xl"/>
          <Divider label={"Servizio richiesto"}/>
          <Grid>
            <Col sm={3} xs={12}>
              <Select data={[
                {value: "DisinfestazioneZanzare", label: "Disinfestazione Zanzare"},
                {value: "Derattizzazione / Monitoraggio roditori", label: "Derattizzazione / Monitoraggio roditori"},
                {value: "Vespe / Calabroni", label: "Vespe / Calabroni"},
                {value: "Deblattizzazione (Blatta orientalis)", label: "Deblattizzazione (Blatta orientalis)"},
                {value: "Deblattizzazione (Blattella germanica)", label: "Deblattizzazione (Blattella germanica)"},
                {value: "Allontanamento volatili", label: "Allontanamento volatili"},
                {value: "Altro", label: "Altro"},
              ]} label="Servizio"  {...form.getInputProps("service")} disabled={!editMode}/>
            </Col>
          </Grid>
          
          <Space h="xl"/>
          <Divider label={"Informazioni aggiuntive"}/>
          <Grid>
            <Col sm={6} xs={12}>
              <Textarea label="Note"  {...form.getInputProps("notes")} disabled={!editMode} autosize={true} maxRows={60}/>
            </Col>
          </Grid>
          <Grid>
            <Col sm={3} xs={12}>
              <TextInput label="Creato da" disabled  {...form.getInputProps("createdBy")} />
            </Col>
          </Grid>
          <Space h="xl"/>
          <Button type={"submit"} disabled={!editMode || submitting}>Salva</Button>
        </form>
      </Container></>
  );
}

export default FirstContactDetail;
