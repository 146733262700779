import {TextInput, Title} from "@mantine/core";
import {useDebouncedValue, useFocusTrap} from "@mantine/hooks";
import React, {useEffect, useState} from "react";
import {IconBarcode} from "@tabler/icons-react";

// import {useZxing} from "react-zxing";

interface Props {
  setResult: (barcode: string) => void;
  title: string;
}

const ItemScanner: React.FC<Props> = ({setResult, title}: Props) => {
  const [inputValue, setInputValue] = useState<string>("")
  const [useDebouncedValueElement] = useDebouncedValue(inputValue, 500)
  const focusTrapRef = useFocusTrap();
  
  useEffect(() => {
    setResult(useDebouncedValueElement)
  }, [useDebouncedValueElement, setResult])
  
  return (
    <>
      <Title order={1} mb={32} sx={{textAlign: "center"}}>
        {title}
      </Title>
      <div style={{
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <IconBarcode/>
        <TextInput
          placeholder='Scannerizza il barcode'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          sx={{marginLeft: 8}}
          ref={focusTrapRef}
        />
      </div>
    </>
  );
};

export default ItemScanner;
