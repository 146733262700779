import React, {useEffect} from "react";
import {
  Button,
  Col,
  Container,
  Divider,
  FileButton,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  Select,
  Space,
  Switch,
  TextInput,
  Title
} from "@mantine/core";
import {
  useDeleteInventoryMutation,
  useGetInventoryMovementsQuery,
  useGetInventoryQuery,
  useUpdateInventoryMutation
} from "../../redux/apis/inventoryApi";
import {useLocation, useRoute} from "wouter";
import {useForm} from "@mantine/form";
import {showNotification} from "@mantine/notifications";
import {useDisclosure} from "@mantine/hooks";
import {IconArrowBack, IconCheck, IconDeviceFloppy, IconDownload, IconTrash, IconX} from "@tabler/icons-react";
import {itemCategories, units} from "../../constants";
import keycloak from "../../Keycloak";
import Movements from "./components/Movements";

async function uploadFile(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  
  const response = await fetch('/api/document', {
    method: 'POST',
    body: formData,
    headers: {
      'Authorization': 'Bearer ' + keycloak.token
    }
  });
  
  if (!response.ok) {
    throw new Error('File upload failed');
  }
  
  return await response.json();
}

//TODO: make a reusable function, that's a duplicate
async function downloadFile(id: string, filename: string) {
  const response = await fetch(`/api/document/${id}/file`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + keycloak.token
    }
  })
  
  if (!response.ok) {
    throw new Error('File download failed');
  }
  
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'file.pdf';
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

const WarehouseItemDetail: React.FC = () => {
    // @ts-ignore
    const [, {id}] = useRoute("/warehouse/item/:id");
    const {data} = useGetInventoryQuery(id);
    const {data: movements} = useGetInventoryMovementsQuery(data?.id, {skip: data?.id === undefined})
    const [updateInventoryItem, {isLoading}] = useUpdateInventoryMutation();
    const [deleteInventoryItem] = useDeleteInventoryMutation();
    const [opened, {close, open}] = useDisclosure(false);
    const [loadingData, setLoadingData] = React.useState(true);
    const [editMode, editModeHandler] = useDisclosure(false);
    const [_, setLocation] = useLocation();
    
    const form = useForm({
      initialValues: {
        id: data?.id,
        name: "",
        barcode: "",
        capacityThreshold: 0,
        rack: "",
        category: "",
        unit: "",
        photo: "",
        safetyDataSheetId: "",
        safetyDataSheetName: "",
        technicalDataSheetId: "",
        technicalDataSheetName: "",
      },
    });
    
    useEffect(() => {
      setLoadingData(!data);
      form.setValues({
        id: data?.id,
        name: data?.name,
        barcode: data?.barcode,
        capacityThreshold: data?.capacityThreshold,
        category: data?.category,
        unit: data?.unit,
        photo: data?.photo,
        rack: data?.rack,
        safetyDataSheetId: data?.safetyDataSheet?.id,
        safetyDataSheetName: data?.safetyDataSheet?.fileName,
        technicalDataSheetId: data?.technicalDataSheet?.id,
        technicalDataSheetName: data?.technicalDataSheet?.fileName,
      });
    }, [data])
    
    return (
      <Container
        fluid
        sx={{
          backgroundColor: "white",
          borderRadius: 6,
          padding: 12,
          minHeight: "80vh",
          margin: "8px 0px"
        }}
      >
        <LoadingOverlay visible={isLoading || loadingData} overlayBlur={2}/>
        <Modal opened={opened} onClose={close} size="auto" title="Conferma cancellazione">
          Sei sicuro di voler cancellare l'elemento?
          <Group noWrap mt="md">
            <Button color={"red"} onClick={() => {
              setLoadingData(true);
              deleteInventoryItem(data?.id).then(() => {
                showNotification({
                  title: "Prodotto eliminato",
                  message: "Il prodotto è stato eliminato con successo",
                  color: "green",
                });
                setLocation("/warehouse");
              });
            }}><IconTrash color="white" style={{marginRight: 8}}/> Elimina</Button>
            <Button onClick={close}><IconArrowBack color="white" style={{marginRight: 8}}/> Annulla</Button>
          </Group>
        </Modal>
        <Group style={{
          margin: "10px 0",
        }}>
          <Switch
            checked={editMode}
            onChange={() => editModeHandler.toggle()}
            color="teal"
            size="md"
            label="Modifica abilitata"
            thumbIcon={
              editMode ? (
                <IconCheck size={12} color={"teal"} stroke={3}/>
              ) : (
                <IconX size={12} color={"#40E0D0"} stroke={3}/>
              )
            }
          />
        </Group>
        <Title mb={8}>Prodotto: {data?.name}</Title>
        <Space h="lg"/>
        <form onSubmit={form.onSubmit(values => {
          updateInventoryItem(values).then(() => {
            showNotification({
              title: "Prodotto aggiornato",
              message: "Il prodotto è stato aggiornato con successo",
              color: "teal",
            });
            if (values.barcode !== data?.barcode) {
              setLocation(`/warehouse/item/${values.barcode}`);
            }
          });
        })}>
          <Grid>
            <Col md={4} xs={12}>
              <TextInput
                label="Barcode"
                value={data?.barcode}
                {...form.getInputProps("barcode")}
                disabled={!editMode}
              />
            </Col>
            <Col md={5} xs={12}>
              <TextInput
                label="Nome prodotto"
                value={data?.name}
                {...form.getInputProps("name")}
                disabled={!editMode}
              />
            </Col>
          </Grid>
          <Grid>
            <Col md={2} xs={12}>
              <TextInput
                label="Quantita"
                value={data?.quantity}
                disabled
              />
            </Col>
            <Col md={2} xs={12}>
              <TextInput
                label="Soglia critica"
                value={data?.capacityThreshold}
                {...form.getInputProps("capacityThreshold")}
                disabled={!editMode}
              />
            </Col>
            <Col md={2} xs={12}>
              <Select
                data={units}
                label="Unità di misura"
                required
                {...form.getInputProps("unit")}
                disabled={!editMode}
              />
            </Col>
          </Grid>
          <Grid>
            <Col md={3} xs={12}>
              <TextInput
                label="Scaffale"
                value={data?.rack}
                {...form.getInputProps("rack")}
                disabled={!editMode}
              />
            </Col>
            <Col md={4} xs={12}>
              <Select
                data={itemCategories}
                label="Categoria"
                required
                {...form.getInputProps("category")}
                disabled={!editMode}
              />
            </Col>
          </Grid>
          <Space h="xl"/>
          <Divider label={"Documentazione"}/>
          <Space h="xs"/>
          <Grid>
            <Col span={12}>
              <div style={{
                fontSize: 14,
                fontWeight: 500,
                color: "#212529",
                marginBottom: 8,
              }}>Scheda sicurezza
              </div>
              <div style={{display: 'flex', alignItems: "center", gap: 16}}>
                {form.values?.safetyDataSheetId &&
                    <Button style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }} compact color={"blue"} variant={"light"} onClick={() => {
                      try {
                        downloadFile(form.values.safetyDataSheetId, form.values.safetyDataSheetName);
                      } catch (error) {
                        console.error(error);
                      }
                    }}><IconDownload size={16} style={{minWidth: 16}}/> {form.values.safetyDataSheetName}
                    </Button>
                }
                <FileButton
                  onChange={(files) => {
                    setLoadingData(true)
                    uploadFile(files).then((response) => {
                        form.setValues({
                          ...form.values,
                          safetyDataSheetId: response.id,
                          safetyDataSheetName: response.fileName,
                        });
                      })
                      .finally(() => setLoadingData(false));
                  }} accept="pdf"
                >
                  {(props) => <Button compact {...props} disabled={!editMode}>Carica</Button>}
                </FileButton>
                {form.values.safetyDataSheetId &&
                    <Button compact color="red" disabled={!editMode} onClick={() => {
                      form.setValues({
                        ...form.values,
                        safetyDataSheetId: undefined,
                        safetyDataSheetName: undefined,
                      });
                    }}><IconTrash size={16}/></Button>}
              </div>
            </Col>
            <Col span={12}>
              <div style={{
                fontSize: 14,
                fontWeight: 500,
                color: "#212529",
                marginBottom: 8,
              }}>Scheda tecnica
              </div>
              <div style={{display: 'flex', alignItems: "center", gap: 16}}>
                {form.values?.technicalDataSheetId &&
                    <Button style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }} compact color={"blue"} variant={"light"} onClick={() => {
                      try {
                        downloadFile(form.values.technicalDataSheetId, form.values.technicalDataSheetName);
                      } catch (error) {
                        console.error(error);
                      }
                    }}><IconDownload size={16} style={{minWidth: 16}}/> {form.values.technicalDataSheetName}
                    </Button>
                }
                <FileButton
                  onChange={(files) => {
                    setLoadingData(true)
                    uploadFile(files).then((response) => {
                        form.setValues({
                          ...form.values,
                          technicalDataSheetId: response.id,
                          technicalDataSheetName: response.fileName,
                        });
                      })
                      .finally(() => setLoadingData(false));
                  }} accept="pdf"
                >
                  {(props) => <Button compact {...props} disabled={!editMode}>Carica</Button>}
                </FileButton>
                {form.values.technicalDataSheetId &&
                    <Button compact color="red" disabled={!editMode} onClick={() => {
                      form.setValues({
                        ...form.values,
                        technicalDataSheetId: undefined,
                        technicalDataSheetName: undefined,
                      });
                    }}><IconTrash size={16}/></Button>}
              </div>
            </Col>
          </Grid>
          <Space h="xl"/>
          <Divider label={"Movimentazioni"}/>
          <Space h="xl"/>
          <Grid>
            <Col span={12}>
              <Movements movements={movements ?? []}/>
            </Col>
          </Grid>
          <Space h="xl"/>
          <Group noWrap mt="md">
            <Button type={"submit"} disabled={!editMode}><IconDeviceFloppy color="white" style={{marginRight: 8}}/> Salva</Button>
            <Button onClick={open} color={"red"}><IconTrash color="white" style={{marginRight: 8}}/> Elimina</Button>
          </Group>
        </form>
      </Container>
    )
  }
;

export default WarehouseItemDetail;
