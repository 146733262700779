import * as React from "react";
import FullWidthContainer from "../components/FullWidthContainer";
import { DataTable } from "../components/table/DataTable";
import { useCustomer } from "../hooks/useCustomer";
import { Box, Title } from "@mantine/core";

const TITLE_HEIGHT = 42;

const Customers = () => {
  const {
    customers,
    pages,
    currentPage,
    tableFilters,
    setTableFilters,
    setCurrentPage,
    setFilter,
    setOrderColumn,
    orderColumnRef,
    orderDescending,
  } = useCustomer();

  return (
    <FullWidthContainer>
      <Title order={1} mb={8} sx={{ height: TITLE_HEIGHT }}>
        Clienti
      </Title>
      <Box sx={{ height: `calc(100% - ${TITLE_HEIGHT}px)` }}>
        <DataTable
          tableFilters={tableFilters}
          setTableFilters={setTableFilters}
          onSearch={(searchValue) => setFilter(searchValue)}
          onSort={(columnRef) => {
            setOrderColumn(columnRef);
          }}
          sortingColumn={orderColumnRef}
          sortingDesc={orderDescending}
          header={[
            {
              accessor: "id",
              visible: false,
            },
            {
              accessor: "erpCode",
              visible: true,
              label: "Rif. Gestionale",
            },
            {
              accessor: "description",
              visible: true,
              label: "Descrizione",
            },
            {
              accessor: "address",
              visible: true,
              label: "Indirizzo",
            },
            {
              accessor: "city",
              visible: true,
              label: "Città",
            },
            {
              accessor: "zipCode",
              visible: true,
              label: "Cap",
            },
            {
              accessor: "province",
              visible: true,
              label: "Provincia",
            },
            {
              accessor: "country",
              visible: true,
              label: "Nazione",
            },
            {
              accessor: "zone",
              visible: true,
              label: "Zona",
            },
            {
              accessor: "cf",
              visible: true,
              label: "Codice fiscale",
            },
            {
              accessor: "vat",
              visible: true,
              label: "P.Iva",
            },
          ]}
          data={customers}
          pages={pages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Box>
    </FullWidthContainer>
  );
};

export default Customers;
