import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {IconFileUpload} from "@tabler/icons-react";
import {Paper, Text} from "@mantine/core";
import keycloak from "../Keycloak";
import imageCompression from "browser-image-compression";


const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

async function uploadFile(file: File) {
  const options = {
    maxSizeMB: 0.5,
    useWebWorker: true,
    maxWidthOrHeight: 1920
  };
  try {
    const compressedFile = await imageCompression(file, options);
    const formData = new FormData();
    
    formData.append('file', new File([compressedFile], compressedFile.name));
    const response = await fetch('/api/document', {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + keycloak.token
      }
    });
    
    if (!response.ok) {
      throw new Error('File upload failed');
    }
    return await response.json();
  } catch (error) {
    throw new Error('File upload failed');
  }
}


function FileUploader({
                        onFileUpload,
                        setIsUploading
                      }: { onFileUpload: (id: string) => void, setIsUploading: (value: boolean) => void }) {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    },
    onDrop: acceptedFiles => {
      setIsUploading(true);
      setFiles((prev) => [...prev, ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))]);
      uploadFile(acceptedFiles[0]).then(r => {
        onFileUpload(r.id);
        setIsUploading(false);
      });
    },
  });
  
  const thumbs = files.map(file => (
    <div style={{
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: 'border-box'
    }} key={file.name}>
      <div style={thumbInner}>
        <img
          alt={'uploaded image'}
          src={file.preview}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      </div>
    </div>
  ));
  
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);
  
  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})} style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
        <input {...getInputProps()} />
        <Paper shadow="md" p="md" withBorder>
          <Text style={{
            display: 'flex',
          }}><IconFileUpload color={'#228be6'} size={48}/> Clicca qui per caricare foto della lavorazione</Text>
        </Paper>
      </div>
      <aside style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
      }}>
        {thumbs}
      </aside>
    </section>
  );
}

export default FileUploader;