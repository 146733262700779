import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {TableFilter} from "../../components/table/DataTable";
import {InventoryApi} from "../apis/inventoryApi";

export const DEFAULT_PAGE_LIMIT = 10;

// Define a type for the slice state
interface IInventoryState {
  pages: number;
  currentPage: number;
  filter: string;
  orderColumnRef: string;
  orderDescending: boolean;
  tableFilters: TableFilter[];
}

// Define the initial state using that type
const initialState: IInventoryState = {
  pages: 1,
  currentPage: 1,
  filter: "",
  orderColumnRef: "LastUpdate",
  orderDescending: true,
  tableFilters: []
};

// @ts-ignore
export const customerSlice = createSlice({
  name: "inventory",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setOrderColumnRef: (state, action) => {
      state.orderColumnRef = action.payload;
    },
    setOrderDescending: (state, action) => {
      state.orderDescending = action.payload;
    },
    setTableFilters: (state, action: PayloadAction<TableFilter>) => {
      const filters = state.tableFilters.filter(
        (prevFilter) => prevFilter.columnRef !== action.payload.columnRef
      );
      
      if (!action.payload.value) state.tableFilters = filters;
      else state.tableFilters = [...filters, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      InventoryApi.endpoints.getInventoryItems.matchFulfilled,
      (state, action) => {
        state.pages = Math.ceil(action.payload.length / DEFAULT_PAGE_LIMIT);
      }
    );
  },
});

export const {
  setCurrentPage,
  setFilter,
  setTableFilters,
  setOrderDescending,
  setOrderColumnRef,
} = customerSlice.actions;

export default customerSlice.reducer;
