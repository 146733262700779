import { createSlice } from "@reduxjs/toolkit";

interface ICustomerState {
  customerFilter: string;
}

// Define the initial state using that type
const initialState: ICustomerState = {
  customerFilter: "",
};

// @ts-ignore
export const inspectionSlice = createSlice({
  name: "inspection",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCustomerFilter: (state, action) => {
      state.customerFilter = action.payload;
    },
  },
});

export const { setCustomerFilter } = inspectionSlice.actions;

export default inspectionSlice.reducer;
