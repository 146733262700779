import React from "react";
import Dashboard from "./pages/Dashboard";
import Customers from "./pages/Customers";
import Layout from "./pages/Layout";
import Settings from "./pages/Settings";
import Inspections from "./pages/Inspections";
import InspectionDetail from "./pages/Inspections/InspectionDetail";
import InspectionCreate from "./pages/Inspections/InspectionCreate";
import {Route, Switch} from "wouter";
import ProposteTecniche from "./pages/ProposteTecniche";
import ProposteTecnicheDetail from "./pages/ProposteTecnicheDetail";
import ProposteTecnicheCreate from "./pages/ProposteTecnicheCreate";
import InspectionMovements from "./pages/Inspections/InspectionMovements";
import Warehouse from "./pages/Warehouse";
import Upload from "./pages/Warehouse/Upload";
import Download from "./pages/Warehouse/Download";
import WarehouseItemDetail from "./pages/Warehouse/WarehouseItemDetail";
import FirstContact from "./pages/FirstContact/FirstContacts";
import FirstContactCreate from "./pages/FirstContact/FirstContactCreate";
import FirstContactDetail from "./pages/FirstContact/FirstContactDetail";
import {Vehicles} from "./pages/Vehicles/Vehicles";
import {VehicleDetail} from "./pages/Vehicles/VehicleDetail";
import {VehicleCreate} from "./pages/Vehicles/VehicleCreate";
import {ClockingList} from "./pages/Clocking/List";
import {ClockingCreate} from "./pages/Clocking/Create";
import {ClockingDetail} from "./pages/Clocking/Detail";

function App() {
  return (
    <Layout>
      <Switch>
        <Route path="/">
          <Dashboard/>
        </Route>
        <Route path="/customers">
          <Customers/>
        </Route>
        <Route path="/sopralluoghi">
          <Inspections/>
        </Route>
        <Route path="/sopralluoghi/create">
          <InspectionCreate/>
        </Route>
        <Route path="/sopralluoghi/:id">
          <InspectionDetail/>
        </Route>
        <Route path="/proposte">
          <ProposteTecniche/>
        </Route>
        <Route path="/clockings">
          <ClockingList/>
        </Route>
        <Route path="/clockings/create">
          <ClockingCreate/>
        </Route>
        <Route path="/clockings/:id">
          <ClockingDetail/>
        </Route>
        <Route path="/firstcontact">
          <FirstContact/>
        </Route>
        <Route path="/firstcontact/create">
          <FirstContactCreate/>
        </Route>
        <Route path="/firstcontact/:id">
          <FirstContactDetail/>
        </Route>
        <Route path="/warehouse">
          <Warehouse/>
        </Route>
        <Route path="/warehouse/upload">
          <Upload/>
        </Route>
        <Route path="/warehouse/item/:id">
          <WarehouseItemDetail/>
        </Route>
        <Route path="/warehouse/download">
          <Download/>
        </Route>
        <Route path="/proposte/create">
          <ProposteTecnicheCreate/>
        </Route>
        <Route path="/proposte/:id">
          <ProposteTecnicheDetail/>
        </Route>
        <Route path="/vehicles">
          <Vehicles/>
        </Route>
        <Route path="/vehicles/add">
          <VehicleCreate/>
        </Route>
        <Route path="/vehicles/:id">
          {(params: any) => <VehicleDetail id={params.id}/>}
        </Route>
        <Route path="/settings">
          <Settings/>
        </Route>
        <Route path="/test">
          <InspectionMovements/>
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
