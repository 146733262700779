import * as React from "react";
import {ActionIcon, Box, Table, Title} from "@mantine/core";
import {useLocation} from "wouter";
import {IconAlertTriangle, IconListCheck, IconSearch} from "@tabler/icons-react";
import {IInventoryItem} from "../../models/inspection";
import useIsMobile from "../../hooks/useIsMobile";
import {InventoryDashboardCardMobile} from "../../components/inventoryCard/InventoryDashboardCardMobile";

interface IProps {
  reachedThresholdItems: IInventoryItem[];
}

const TITLE_HEIGHT = 42;

const WidgetThresholdReached = ({reachedThresholdItems}: IProps) => {
  const [_, setLocation] = useLocation();
  const isMobile = useIsMobile();

  const itemsByDifference = reachedThresholdItems?.slice().sort((a, b) => {
    let first = a.capacityThreshold - a.quantity;
    let second = b.capacityThreshold - b.quantity;

    return first < second ? 1 : -1;
  }) ?? []

  if (itemsByDifference.length === 0)
    return (<Title size={"h3"} style={{marginBottom: 16, fontWeight: "normal"}}>
      <div style={{display: "flex", alignItems: "center"}}>
        <IconListCheck color={"green"} style={{marginRight: 16}}/>
        Non sono presenti elementi con la soglia critica raggiunta
      </div>
    </Title>)

  return (<>
      <Title size={"h3"} style={{marginBottom: 16, fontWeight: "normal"}}>
        <div style={{display: "flex", alignItems: "center"}}>
          <IconAlertTriangle color={"red"} style={{marginRight: 16}}/>
          Lista elementi con soglia limite raggiunta
        </div>
      </Title>

        {
          isMobile
              ? <Box sx={{marginBottom: TITLE_HEIGHT + 50}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: '10px'}}>
                    {
                        reachedThresholdItems?.map((item: IInventoryItem, index) => (
                            <InventoryDashboardCardMobile
                                key={index}
                                capacityThreshold={item.capacityThreshold}
                                quantity={item.quantity}
                                onClick={(id: string) => setLocation(`/warehouse/item/${id}`)}
                                productName={item.name}
                                barcode={item.barcode}
                            />))
                    }
                </div>
              </Box>
              : <Table striped highlightOnHover withBorder withColumnBorders style={{overflow: "auto", maxHeight: 500}}>
                <thead>
                <th>Barcode</th>
                <th>Nome</th>
                <th>Quantità</th>
                <th>Soglia limite</th>
                <th>Azioni</th>
                </thead>
                <tbody>

                {
                  itemsByDifference.map((m) => {
                    return (
                        <tr key={m.id}>
                          <td>{m.barcode}</td>
                          <td>{m.name}</td>
                          <td>{m.quantity}</td>
                          <td>{m.capacityThreshold}</td>
                          <td>
                            <ActionIcon onClick={() => setLocation(`/warehouse/item/${m.barcode}`)}>
                              <IconSearch/>
                            </ActionIcon>
                          </td>
                        </tr>
                    );
                  })}
                </tbody>
              </Table>
        }

    </>
  );
}

export default WidgetThresholdReached;
