import * as React from "react";
import {useKeycloak} from "@react-keycloak/web";
import InventoryDashboard from "./Dashboard/InventoryDashboard";
import {Container, Title} from "@mantine/core";

const Dashboard = () => {
  const {keycloak} = useKeycloak();
  const ableToSeeWarehouseDashboard = keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("magazzino");
  
  return (
    <Container
      fluid
      sx={{
        backgroundColor: "white",
        borderRadius: 6,
        height: "80vh",
        overflowY: "auto",
        padding: 12,
      }}
    >
      <Title size={"h2"} style={{marginBottom: 16}}>Benvenuto {keycloak.tokenParsed.given_name}!</Title>
      {ableToSeeWarehouseDashboard && <InventoryDashboard/>}
    </Container>
  );
};

export default Dashboard;
