import {Button, Col, Grid, LoadingOverlay, Modal, NumberInput, Select, TextInput, Title,} from "@mantine/core";
import React from "react";
import {useAddInventoryMovementMutation, useAddInventoryMutation,} from "../../../redux/apis/inventoryApi";
import {useForm} from "@mantine/form";
import {showNotification} from "@mantine/notifications";
import {itemCategories, units} from "../../../constants.js";

interface formProps {
  barcode: string;
  username: string;
}

interface Props {
  isOpen: boolean;
  formProps: formProps;
  setIsOpen: (value: boolean) => void;
  resetBarcode: () => void;
}

const CreateItem: React.FC<Props> = ({
                                       isOpen,
                                       formProps,
                                       setIsOpen,
                                       resetBarcode,
                                     }: Props) => {
  const [addInventory, {isLoading}] = useAddInventoryMutation();
  const [addInventoryMovement, {isLoading: loadingAddMovement}] =
    useAddInventoryMovementMutation();
  
  const handleOpen = (value: boolean) => {
    setIsOpen(value);
    resetBarcode();
  };
  
  const form = useForm({
    initialValues: {
      name: "",
      barcode: formProps.barcode,
      quantity: 0,
      username: formProps.username,
      capacityThreshold: 0,
      rack: "",
      category: "",
      unit: "",
      photo: ""
    },
  });
  
  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        handleOpen(!isOpen);
      }}
    >
      <Title order={3} mb={8} sx={{height: 45, textAlign: "center"}}>
        Censisci un nuovo prodotto
      </Title>
      <LoadingOverlay
        visible={isLoading || loadingAddMovement}
        zIndex={1000}
      />
      <Grid>
        <Col span={12}>
          <form
            onSubmit={form.onSubmit(({name, barcode, quantity, capacityThreshold, rack, category, unit}) => {
              const username = formProps.username
              addInventory({
                name: name,
                barcode: barcode,
                capacityThreshold: capacityThreshold,
                rack: rack,
                category: category,
                unit: unit,
                photo: ""
              })
                .unwrap()
                .then((data) => {
                  addInventoryMovement({
                    itemId: data.id,
                    body: {
                      quantity,
                      username,
                      assignedTo: "",
                    },
                  }).then(() => {
                    showNotification({
                      message: "Elemento caricato in inventario!"
                    })
                    handleOpen(!isOpen);
                  });
                })
                .catch(() => {
                  console.error("Something went wrong");
                });
            })}
          >
            <TextInput
              label="Nome prodotto"
              required
              {...form.getInputProps("name")}
            />
            <TextInput
              label="Barcode"
              disabled
              defaultValue={formProps.barcode}
              placeholder={formProps.barcode}
              sx={{
                marginTop: "5%",
              }}
              {...form.getInputProps("barcode")}
            />
            <TextInput
              hidden
              label=""
              disabled
              defaultValue={formProps.username}
              sx={{
                marginTop: "5%",
              }}
              {...form.getInputProps("username")}
            />
            <NumberInput
              label="Quantità"
              required
              sx={{
                margin: "5% 0%",
              }}
              {...form.getInputProps("quantity")}
            />
            <NumberInput
              label="Soglia critica"
              required
              sx={{
                margin: "5% 0%",
              }}
              {...form.getInputProps("capacityThreshold")}
            />
            <Select sx={{
              margin: "5% 0%",
            }}
                    data={itemCategories}
                    label="Categoria"
                    required
                    {...form.getInputProps("category")}
            />
            <Select sx={{
              margin: "5% 0%",
            }}
                    data={units}
                    label="Unità di misura"
                    required
                    {...form.getInputProps("unit")}
            />
            <TextInput
              label="Scaffale"
              sx={{
                margin: "5% 0%",
              }}
              {...form.getInputProps("rack")}
            />
            <Button
              type="submit"
              size="sm"
              sx={{
                marginBottom: "5%",
              }}
            >
              Crea
            </Button>
          </form>
        </Col>
      </Grid>
    </Modal>
  );
};

export default CreateItem;
