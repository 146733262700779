import {Badge, Button, Card, Group, Text} from "@mantine/core"
import React from "react"
import {IconSearch} from "@tabler/icons-react";

interface Props {
  productName: string,
  barcode: string,
  quantity: number,
  capacityThreshold: number,
  onClick: (id: string) => void,
  isThreshold?: boolean,
}


export const InventoryDashboardCardMobile: React.FC<Props> = ({
                                                                productName,
                                                                barcode,
                                                                quantity,
                                                                onClick,
                                                                capacityThreshold,
                                                              }: Props) =>
  (<Card shadow="md"
         radius='md'
         withBorder
         sx={{
           flexDirection: 'column',
           display: 'flex',
           width: '100%',
           alignItems: "start",
           justifyContent: 'center',
         }}>
    <Text weight={700} size={16} sx={{lineHeight: '20px'}}>{productName}</Text>
    <Group position="apart" mt="md" mb="xs" sx={{width: '100%', flexWrap: 'nowrap'}}>
      <Badge color={quantity > capacityThreshold ? "green" : "red"} variant="light" w={"30%"}>
        {quantity}/{capacityThreshold ?? "0"}
      </Badge>
      <Button
        color='blue'
        variant={"light"}
        radius='md'
        onClick={() => onClick(barcode)}
      >
        <IconSearch size={16}/>
      </Button>
    </Group>
  </Card>)