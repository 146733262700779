import {Navbar, ScrollArea} from "@mantine/core";
import * as React from "react";
import MenuItem from "./MenuItem";
import {
  IconBuildingWarehouse,
  IconCar,
  IconClockCheck,
  IconDeviceLandlinePhone,
  IconDownload,
  IconFlag,
  IconHome,
  IconList,
  IconMap2,
  IconScript,
  IconSettings,
  IconUpload,
  IconUsers
} from "@tabler/icons-react";
import {useKeycloak} from "@react-keycloak/web";

type NavigationBarType = {
  opened: boolean;
};

function NavigationBar(props: NavigationBarType) {
  const {keycloak} = useKeycloak();
  
  const subItems = [];
  if (keycloak.hasRealmRole("magazzino_carica"))
    subItems.push({
      label: "Carica",
      link: "/warehouse/upload",
      icon: <IconUpload/>
    })
  
  if (keycloak.hasRealmRole("magazzino_scarica"))
    subItems.push({
      label: "Scarica",
      link: "/warehouse/download",
      icon: <IconDownload/>
    })
  
  
  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!props.opened}
      width={{sm: 200, lg: 300}}
    >
      <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
        {
          keycloak.hasRealmRole("magazzino") && (<>
              <MenuItem icon={<IconHome/>} label={"Dashboard"} link={"/"}/>
            </>
          )
        }
        {
          keycloak.hasRealmRole("firstcontacts") && (<>
              <MenuItem icon={<IconDeviceLandlinePhone/>} label={"Moduli primo contatto"} link={"/firstcontact"}/>
            </>
          )
        }
        {
          keycloak.hasRealmRole("admin") && (<>
            <MenuItem icon={<IconUsers/>} label={"Clienti"} link={"/customers"}/>
            <MenuItem icon={<IconFlag/>} label={"Sopralluoghi"} link={"/sopralluoghi"}/>
            <MenuItem icon={<IconScript/>} label={"Proposte"} link={"/proposte"}/>
          </>)
        }
        {keycloak.hasRealmRole("timbratura") && (
          <MenuItem icon={<IconMap2/>}
                    label={"Timbratura"}
                    link={"/clockings"}
                    haveSubItem={true}
                    subItems={[{
                      label: "Lista",
                      link: "/clockings",
                      icon: <IconList/>
                    },
                      {
                        label: "Timbra",
                        link: "/clockings/create",
                        icon: <IconClockCheck/>
                      }]}
          />
        )}
        {!keycloak.hasRealmRole("timbratura") && (
          <MenuItem icon={<IconClockCheck/>}
                    label={"Timbratura"}
                    link={"/clockings/create"}
          />
        )}
        {
          (keycloak.hasRealmRole("magazzino") || keycloak.hasRealmRole("magazzino_carica") || keycloak.hasRealmRole("magazzino_scarica")) && (
            <MenuItem
              icon={<IconBuildingWarehouse/>}
              label={"Magazzino"}
              link={keycloak.hasRealmRole("magazzino") ? "/warehouse" : "#"}
              haveSubItem={true}
              subItems={subItems}
            />
          )
        }
        {
          (keycloak.hasRealmRole("automezzi")) && (
            <MenuItem
              icon={<IconCar/>}
              label="Automezzi"
              link={keycloak.hasRealmRole("automezzi") ? '/vehicles' : '#'}
            
            />
          )
        }
        {
          keycloak.hasRealmRole("admin") && (<MenuItem
            icon={<IconSettings/>}
            label={"Settings"}
            link={"/settings"}
          />)
        }
      
      </Navbar.Section>
    </Navbar>
  )
    ;
}

export default NavigationBar;
