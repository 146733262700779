import * as React from "react";
import {Container} from "@mantine/core";

const FullWidthContainer = (props) => {
  return (
    <Container
      fluid
      sx={{
        backgroundColor: "white",
        borderRadius: 6,
        height: "80vh",
        padding: 12,
        ...props.style
      }}
    >
      {props.children}
    </Container>
  );
};

export default FullWidthContainer;
