import {Alert, AppShell, Avatar, Box, Burger, Footer, Header, MediaQuery, Menu, useMantineTheme,} from "@mantine/core";
import NavigationBar from "../components/NavigationBar";
import React, {useEffect, useState} from "react";
import logo from "../images/logo.jpg";
import logoGoldService from "../images/logoGoldService.png";
import {useKeycloak} from "@react-keycloak/web";
import {IconAlertCircle, IconLogout} from "@tabler/icons-react";
import {useLocation} from "wouter";
import {useNetwork} from "@mantine/hooks";
import useGetCompany, {Company} from "../hooks/useGetCompany";

const Layout = (props) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [location] = useLocation();
  const networkStatus = useNetwork();
  const company = useGetCompany();
  
  useEffect(() => {
    setOpened(false);
  }, [location])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {keycloak} = useKeycloak();
  
  return (
    <AppShell
      styles={{
        main: {
          position: "relative",
          background:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
          width: "100%",
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      navbar={<NavigationBar opened={opened}/>}
      footer={
        <Footer height={60} p="md">
          Insector {new Date().getFullYear()}
        </Footer>
      }
      header={
        <Header height={70} p="md">
          <Box sx={{display: "flex", alignItems: "center", height: "100%"}}>
            <MediaQuery largerThan="sm" styles={{display: "none"}}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}>
              
              {company === Company.Insector && (
                <Box
                  sx={{
                    width: "200px",
                  }}
                >
                  <img
                    src={logo}
                    width={300}
                    height={100}
                    alt={"insector logo"}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
              )}
              {company === Company.GoldService && (
                <Box
                  sx={{
                    width: "200px",
                  }}
                >
                  <img
                    src={logoGoldService}
                    width={300}
                    height={100}
                    alt={"goldservice logo"}
                    style={{
                      width: "100%",
                      height: "auto",
                    }}
                  />
                </Box>
              )}
              {!!keycloak.authenticated && (
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Avatar color="cyan" style={{cursor: "pointer"}}
                            radius="xl">{keycloak.tokenParsed.given_name[0] ?? ""}{keycloak.tokenParsed.family_name[0] ?? ""}</Avatar>
                  </Menu.Target>
                  
                  <Menu.Dropdown>
                    <Menu.Label>Benvenuto, {keycloak.tokenParsed.name}</Menu.Label>
                    <Menu.Item icon={<IconLogout size={14}/>} onClick={() => keycloak.logout()}>Logout</Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              )}
            </div>
          </Box>
        </Header>
      }
    >
      {networkStatus.online ? null : (
        <Alert style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          width: "100%",
        }} icon={<IconAlertCircle size={16}/>} title={"Offline"}>
          Le informazioni visualizzate potrebbero non essere aggiornate
        </Alert>)}
      {
        props.children
      }
    </AppShell>
  )
    ;
};

export default Layout;
