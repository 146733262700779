import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {TableFilter} from "../../components/table/DataTable";
import {ClockingApi} from "../apis/clockingApi";

export const DEFAULT_PAGE_LIMIT = 100;

// Define a type for the slice state
interface IIClockingState {
  pages: number;
  currentPage: number;
  filter: string;
  orderColumnRef: string;
  orderDescending: boolean;
  tableFilters: TableFilter[];
}

const initialState: IIClockingState = {
  pages: 1,
  currentPage: 1,
  filter: "",
  orderColumnRef: "",
  orderDescending: false,
  tableFilters: [],
};

// @ts-ignore
export const ClockingsSlice = createSlice({
  name: "Clocking",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setOrderColumnRef: (state, action) => {
      state.orderColumnRef = action.payload;
    },
    setOrderDescending: (state, action) => {
      state.orderDescending = action.payload;
    },
    setTableFilters: (state, action: PayloadAction<TableFilter>) => {
      const filters = state.tableFilters.filter(
        (prevFilter) => prevFilter.columnRef !== action.payload.columnRef
      );
      
      if (!action.payload.value) state.tableFilters = filters;
      else state.tableFilters = [...filters, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ClockingApi.endpoints.getClockings.matchFulfilled,
      (state, action) => {
        state.pages = Math.ceil(action.payload.length / DEFAULT_PAGE_LIMIT);
      }
    );
  },
});

export const {
  setCurrentPage,
  setFilter,
  setTableFilters,
  setOrderDescending,
  setOrderColumnRef,
} = ClockingsSlice.actions;

export default ClockingsSlice.reducer;
