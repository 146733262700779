import {fetchBaseQuery} from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import {tokenReceived} from '../slices/userSlice'
import keycloak from '../../Keycloak'
import {RootState} from "../store";

const baseQuery = fetchBaseQuery({
  baseUrl: '/',
  prepareHeaders: (headers, {getState, endpoint}) => {
    const user = (getState() as RootState).user

    if (user && endpoint !== 'refresh') {
      headers.set('Authorization', `Bearer ${user.token}`)
    }
    return headers
  }
})

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await keycloak.updateToken(1).then((refreshed) => {
    api.dispatch(tokenReceived(keycloak.token))
  }).catch((error) => console.error("Failed to refresh token: " + error))

  return baseQuery(args, api, extraOptions)
}