import * as React from "react";
import FullWidthContainer from "../components/FullWidthContainer";
import { Button } from "@mantine/core";

const Settings = () => {
  return (
    <FullWidthContainer>
      <Button onClick={() => alert("sincro avviata")}>
        Sincronizza gestionale
      </Button>
    </FullWidthContainer>
  );
};

export default Settings;
