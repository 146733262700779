import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQueryWithReauth} from "./authApi";
import {IListResponse} from "../../models/api";
import {IClocking} from "../../models/clocking";

export interface IGetClockingRequest {
  search: string;
  orderBy: string;
  descending: boolean;
  limit: number;
  skip: number;
  columnsFilter: string[];
  columnsFiltersValues: string[];
}

//IAddClocking should also omit customer property
export interface IAddClocking extends Omit<IClocking, "id" | "customer"> {
  customerId: string;
}

export const ClockingApi = createApi({
  reducerPath: "ClockingApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getClockings: builder.query<IListResponse<IClocking>, IGetClockingRequest>({
      query: (args) => {
        const columnFilters = args.columnsFilter.map(
          (x) => `&columnsToFilter=${x}`
        );
        const columnFiltersValues = args.columnsFiltersValues.map(
          (x) => `&columnsFilterValues=${x}`
        );
        
        return {
          url: `/api/clocking?search=${args.search}&orderBy=${
            args.orderBy
          }&orderDesc=${args.descending}&limit=${args.limit}&skip=${
            args.skip
          }${columnFilters.join("&")}${columnFiltersValues.join("&")}`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    getClockingsByUserId: builder.query<IClocking[], string>({
      query: (args) => {
        
        return {
          url: `/api/clocking/user/${args}`,
        };
      },
      keepUnusedDataFor: 1,
    }),
    getClocking: builder.query<IClocking, string>({
      query: (id) => `/api/Clocking/${id}`,
      keepUnusedDataFor: 1,
    }),
    updateClocking: builder.mutation<IClocking, IClocking>({
      query: (Clocking) => ({
        url: `/api/clocking/${Clocking.id}`,
        method: "PUT",
        body: Clocking,
      }),
    }),
    addClocking: builder.mutation<IClocking, IAddClocking>({
      query: (body) => {
        return {
          url: "api/clocking",
          method: "POST",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetClockingQuery,
  useGetClockingsQuery,
  useGetClockingsByUserIdQuery,
  useUpdateClockingMutation,
  useAddClockingMutation,
} = ClockingApi;
