import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {LoadingOverlay, MantineProvider} from "@mantine/core";
import {store} from "./redux/store";
import {Provider} from "react-redux";
import {NotificationsProvider} from "@mantine/notifications";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "./Keycloak";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <ReactKeycloakProvider authClient={keycloak}
                               initOptions={{checkLoginIframe: false, onLoad: 'login-required'}}
                               LoadingComponent={<LoadingOverlay visible={true}/>}>
            <MantineProvider withGlobalStyles withNormalizeCSS>
                <NotificationsProvider>
                    <App/>
                </NotificationsProvider>
            </MantineProvider>
        </ReactKeycloakProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if ('serviceWorker' in navigator && !window.location.href.includes("localhost")) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((error) => {
                console.log('Service Worker registration failed:', error);
            });
    });
}
