import {Badge, Button, Card, Group, Text} from "@mantine/core"
import React from "react"
import {IconCalendarTime, IconCategory, IconSignature, IconSignatureOff, IconUser} from "@tabler/icons-react";
import {IClocking} from "../../models/clocking";

interface Props {
  clocking: IClocking //TODO: fix this
  onClick: (id: string) => void
  submitText?: string
  submitEnabled?: boolean
}

export const ClockingListItemCardMobile: React.FC<Props> = ({
                                                              clocking,
                                                              onClick,
                                                              submitText = "Visualizza",
                                                              submitEnabled = true
                                                            }: Props) => {
  
  return <Card shadow="md" radius='md' withBorder sx={{margin: '20px'}}>
    <Group position="apart" mt="md" mb="xs">
      <Text weight={700} size={18} w={"50%"}>{clocking?.customer.description}</Text>
      <Badge color={clocking.dateEnd ? "green" : "gray"} variant="light" w={"40%"}>
        {clocking.dateEnd ? "Completato" : "In corso"}
      </Badge>
    </Group>
    <hr/>
    <Text color="dimmed">
      <div style={{display: "flex"}}>
        {clocking.dateEnd ? <IconCalendarTime style={{marginRight: 16}}/> : <IconCategory style={{marginRight: 16}}/>}
        {clocking.dateEnd ? `Inizio: ${new Date(clocking.dateStart.toString()).toLocaleDateString()} - Fine: ${new Date(clocking.dateEnd.toString()).toLocaleDateString()}` : `Inizio: ${new Date(clocking.dateStart.toString()).toLocaleDateString()}`}
      </div>
      <div style={{display: "flex"}}>
        {clocking.signature ? <IconSignature style={{marginRight: 16}}/> :
          <IconSignatureOff style={{marginRight: 16}}/>}
        {clocking.signature ? "Firmato" : "Non firmato"}
      </div>
      <div style={{display: "flex"}}>
        <IconUser style={{marginRight: 16}}/>
        {clocking.userId}
      </div>
    </Text>
    
    <Button
      disabled={!submitEnabled}
      color='blue'
      variant={"light"}
      fullWidth sx={{marginTop: '20px'}}
      radius='md'
      onClick={() => onClick(clocking.id)}
    >{submitText}</Button>
  </Card>
}